<template>
    <div>
        <Header />
        <main class="main" :class="this.$store.state.showTopMenu == true ? 'collapsed ' : 'uncollapsed'">
            <div class="content container-fluid academy">
                <div class="row align-items-center justify-content-between">


                    <div class="col-auto">
                        <h1 class="page-title">რაიონის დამატება</h1>
                    </div>
                </div>
                <div class="content container-fluid academy px-0">
                    <div class="bg-body  shadow-sm bg-light fullrounded ">

                        <div class=" p-3 border roundedtop ">
                            <div class="form-group">
                                <label class="">აირჩიე რეგიონი </label>
                                <v-select class="p2" placeholder="აირჩიე ქალაქი" :options="[
                                    'თბილისი',
                                    'რუსთავი',
                                    'ბათუმი',
                                    'ქუთაისი',
                                    'ფოთი',
                                ]"></v-select>
                            </div>
                            <span class="">რაიონის დასახელება</span>
                            <div class="input-group mt-2 ">
                                <input type="text" class="col-12   rounded form-control "
                                    placeholder="რაიონის დასახელება">
                            </div>
                        </div>
                        <div class="p-3 round roundedbotto ">

                            <div class="d-flex justify-content-between align-items-center text-muted  ">
                                <a href="#">
                                    <div class="rounded  bg-green text-white buttonsize">
                                        <div class="col-auto">
                                            <span class=" text-white">
                                                შენახვა
                                            </span>
                                        </div>

                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="footer" :class="this.$store.state.showTopMenu == true ? 'collapsed' : ''">
                <div class="row">
                    <div class="col">
                        <router-link to="/District">
                            <i class="fa fa-arrow-left"></i> უკან
                        </router-link>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
    name: "AddDistrict",
    components: {
        Header
    }
}

</script>

<style scoped>
.roundedtop {
    border-radius: 0.75rem 0.75rem 0 0;
}

.roundedbottom {
    border-radius: 0 0 0.75rem 0.75rem;
}

.fullrounded {
    border-radius: 0.75rem;
}

.round {
    border-bottom: solid 1px;
    border-left: solid 1px;
    border-right: solid 1px;
    border-color: #dee2e6;
    border-radius: 0 0 0.75rem 0.75rem;
}

.bg-green {

    background-color: #00c9a7;
    border-color: #00c9a7;
}

.bg-green:hover {
    background-color: #00a387;
    border-color: #00967d;
}

.buttonsize {
    padding: 0.75em 1em;
    font-size: 12px;
}
</style>