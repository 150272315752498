<template>
<div>
  <Header/>
  <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
    <div class="content container-fluid academy">
      <!-- Row -->
      <div class="row align-items-center">


        <div class="col-12">
          <h1 class="page-title">დეშბორდი</h1>
        </div>

        <!-- Create Test -->
        <div class="col-12 mt-4 d-none">
          <div class="card">
            <div class="card-header">გაყიდვები</div>
            <div class="card-body">
              <h3>100 / 150</h3>
              <div class="progress">
                <div class="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress">
                <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress">
                <div class="progress-bar bg-warning progress-bar-striped progress-bar-animated" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress">
                <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Create Test -->

      </div>
      <!-- End Row -->
    </div>
    <!-- End Content -->
  </main>
  <!-- End Main -->
</div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>

<style scoped>
.progress
{
  margin-top: 10px!important;
}
</style>
