import { render, staticRenderFns } from "./Retail_Client_Information_edit.vue?vue&type=template&id=5474c958&scoped=true&"
import script from "./Retail_Client_Information_edit.vue?vue&type=script&lang=js&"
export * from "./Retail_Client_Information_edit.vue?vue&type=script&lang=js&"
import style0 from "./Retail_Client_Information_edit.vue?vue&type=style&index=0&id=5474c958&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5474c958",
  null
  
)

export default component.exports