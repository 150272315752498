<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title">კორპორატიული დილის დამატება</h1>
          </div>

          <!-- Col -->
          <div class="col-12 mt-4">
            <form class="card">
              <div class="card-header text-dark font-weigt-bold d-flex align-items-center">
                <h1 class="me-3 mb-0">01</h1>
                <div> <h4 class="mb-0">შეავსე კომპანიის მონაცემები</h4> <span class="text-primary d-none" v-b-tooltip.hover title="კორპორატიული კლიენტი"> თიბისი ბანკი</span></div>

              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="identificationCode">საინდეტიფიკაციო კოდი</label>
                    <input class="form-control" id="identificationCode" type="number" placeholder="საინდეტიფიკაციო კოდი">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="nameOfCompany">კომპანიის დასახელება</label>
                    <input class="form-control" id="nameOfCompany" type="text" placeholder="კომპანიის დასახელება">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <router-link to="/Corporate_Client_Identification">
                      <button class="btn btn-primary"><i class="tio-next-ui"></i> შემდეგი</button>
                    </router-link>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </form>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Deals_Overview">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "Create_Corporate_Deal",
  components: {
    Header
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
}
</script>

<style scoped>

</style>