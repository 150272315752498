var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main",class:this.$store.state.showTopMenu == true ? 'collapsed ' : 'uncollapsed'},[_c('div',{staticClass:"content container-fluid academy"},[_vm._m(0),_c('div',{staticClass:"content container-fluid academy px-0"},[_c('div',{staticClass:"bg-body shadow-sm bg-light fullrounded"},[_c('div',{staticClass:"p-3 border roundedtop"},[_c('div',{staticClass:"form-group"},[_c('label',{},[_vm._v("აირჩიე რეგიონი ")]),_c('v-select',{attrs:{"placeholder":"აირჩიე ქალაქი","options":[
                                'თბილისი',
                                'რუსთავი',
                                'ბათუმი',
                                'ქუთაისი',
                                'ფოთი',
                            ]}})],1),_c('div',{staticClass:"form-group"},[_c('label',{},[_vm._v("აირჩიე რაიონი ")]),_c('v-select',{attrs:{"placeholder":"აირჩიე რაიონი","options":[
                                'თბილისი',
                                'რუსთავი',
                                'ბათუმი',
                                'ქუთაისი',
                                'ფოთი',
                            ]}})],1),_c('span',{},[_vm._v("მისამართის დასახელება")]),_vm._m(1)]),_vm._m(2)])])]),_c('div',{staticClass:"footer",class:this.$store.state.showTopMenu == true ? 'collapsed' : ''},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/Address"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" უკან ")])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col-auto"},[_c('h1',{staticClass:"page-title"},[_vm._v("მისამართის დამატება")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group mt-2"},[_c('input',{staticClass:"col-12 rounded form-control",attrs:{"type":"text","placeholder":"მისამართის დასახელება"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 round roundedbotto"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center text-muted"},[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"rounded bg-green text-white buttonsize"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"text-white"},[_vm._v(" შენახვა ")])])])])])])
}]

export { render, staticRenderFns }