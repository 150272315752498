var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main",class:this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'},[_c('div',{staticClass:"content container-fluid academy"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-5 text-end mb-2"},[_c('router-link',{attrs:{"to":"/AddRule"}},[_c('a',{staticClass:"btn btn-custom-dark",attrs:{"href":"AddRule"}},[_vm._v("დამატება "),_c('i',{staticClass:"tio-add"})])])],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"footer",class:this.$store.state.showTopMenu== true ? 'collapsed' : ''},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/Academy"}},[_c('a',{attrs:{"href":"Academy"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" უკან")])])],1)])])]),_vm._m(5)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7 mb-4"},[_c('h1',{staticClass:"page-title"},[_vm._v("წესები და ბრძანებები")]),_c('small',{staticClass:"text-muted"},[_vm._v("ფაილების რაოდენობა: 5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-2"},[_c('div',{staticClass:"card card-hover-shadow card-border-rules"},[_c('div',{staticClass:"card-header d-flex justify-content-between align-items-center"},[_c('a',{staticClass:"text-dark h5 mb-0",attrs:{"href":"Rule"}},[_vm._v(" დიქეი კაპიტალის საოპერაციო საქმიანობა (V9) ")]),_c('div',{staticClass:"d-flex flex-column text-center"},[_c('a',{staticClass:"h4 text-dark mb-2",attrs:{"href":"#"}},[_c('i',{staticClass:"tio-edit"})]),_c('span',{staticClass:"text-danger h4 mb-0",staticStyle:{"cursor":"pointer"},attrs:{"data-bs-toggle":"modal","data-bs-target":"#staticBackdrop"}},[_c('i',{staticClass:"tio-delete"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-2"},[_c('div',{staticClass:"card card-hover-shadow card-border-rules"},[_c('div',{staticClass:"card-header d-flex justify-content-between align-items-center"},[_c('a',{staticClass:"text-dark h5 mb-0",attrs:{"href":"Rule"}},[_vm._v(" დიქეი კაპიტალის მართვის ინსტრუქცია (V3) ")]),_c('div',{staticClass:"d-flex flex-column text-center"},[_c('a',{staticClass:"h4 text-dark mb-2",attrs:{"href":"#"}},[_c('i',{staticClass:"tio-edit"})]),_c('span',{staticClass:"text-danger h4 mb-0",staticStyle:{"cursor":"pointer"},attrs:{"data-bs-toggle":"modal","data-bs-target":"#staticBackdrop"}},[_c('i',{staticClass:"tio-delete"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-2"},[_c('div',{staticClass:"card card-hover-shadow card-border-rules"},[_c('div',{staticClass:"card-header d-flex justify-content-between align-items-center"},[_c('a',{staticClass:"text-dark h5 mb-0",attrs:{"href":"Rule"}},[_vm._v(" ინფორმაცია ძველი მშენებლობების შესახებ ")]),_c('div',{staticClass:"d-flex flex-column text-center"},[_c('a',{staticClass:"h4 text-dark mb-2",attrs:{"href":"#"}},[_c('i',{staticClass:"tio-edit"})]),_c('span',{staticClass:"text-danger h4 mb-0",staticStyle:{"cursor":"pointer"},attrs:{"data-bs-toggle":"modal","data-bs-target":"#staticBackdrop"}},[_c('i',{staticClass:"tio-delete"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-hover-shadow card-border-rules"},[_c('div',{staticClass:"card-header d-flex justify-content-between align-items-center"},[_c('a',{staticClass:"text-dark h5 mb-0",attrs:{"href":"Rule"}},[_vm._v(" ინფორმაცია ქალაქ თბილისი ფუნქციური ზონების შესახებ ")]),_c('div',{staticClass:"d-flex flex-column text-center"},[_c('a',{staticClass:"h4 text-dark mb-2",attrs:{"href":"#"}},[_c('i',{staticClass:"tio-edit"})]),_c('span',{staticClass:"text-danger h4 mb-0",staticStyle:{"cursor":"pointer"},attrs:{"data-bs-toggle":"modal","data-bs-target":"#staticBackdrop"}},[_c('i',{staticClass:"tio-delete"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"staticBackdrop","data-bs-backdrop":"static","data-bs-keyboard":"false","tabindex":"-1","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"staticBackdropLabel"}},[_vm._v("ბრძანების წაშლა")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[_vm._v(" ნამდვილად გსურთ ვიდეო ბრძანების წაშლა? ")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-white",attrs:{"type":"button","data-bs-dismiss":"modal"}},[_vm._v("გაუქმება")]),_c('button',{staticClass:"btn btn-custom-danger",attrs:{"type":"button"}},[_vm._v("თანხმობა")])])])])])
}]

export { render, staticRenderFns }