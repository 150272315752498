var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main",class:this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'},[_c('div',{staticClass:"content container-fluid academy"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-12 mt-4"},[_c('form',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"form-group col-12"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-md-center"},[_c('router-link',{staticClass:"d-flex flex-column flex-md-row align-items-md-center",attrs:{"to":"/Open_Corporate_Deal"}},[_c('button',{staticClass:"btn btn-primary"},[_c('i',{staticClass:"tio-next-ui"}),_vm._v(" შემდეგი")])]),_c('div',{staticClass:"mx-0 mx-md-3 my-3 my-md-0 mb-md-0 text-muted text-center"},[_vm._v("ან")]),_vm._m(10)],1)])])])])])])]),_c('div',{staticClass:"footer",class:this.$store.state.showTopMenu== true ? 'collapsed' : ''},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/Corporate_Client_Information"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" უკან ")])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v("კორპორატიული დილის დამატება")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header text-dark font-weigt-bold d-flex align-items-center"},[_c('h1',{staticClass:"me-3 mb-0"},[_vm._v("02")]),_c('h4',{staticClass:"mb-0"},[_vm._v("შეავსე კლიენტის მონაცემები")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"name"}},[_vm._v("კლიენტის სახელი")]),_c('input',{staticClass:"form-control",attrs:{"id":"name","type":"text","placeholder":"კლიენტის სახელი"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"surname"}},[_vm._v("კლიენტის გვარი")]),_c('input',{staticClass:"form-control",attrs:{"id":"surname","type":"text","placeholder":"კლიენტის გვარი"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"number"}},[_vm._v("ტელეფონის ნომერი")]),_c('input',{staticClass:"form-control",attrs:{"id":"number","type":"number","placeholder":"ტელეფონის ნომერი"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"number2"}},[_vm._v("ტელეფონის ნომერი (მეორე)")]),_c('input',{staticClass:"form-control",attrs:{"id":"number2","type":"number","placeholder":"ტელეფონის ნომერი (მეორე)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"personalId"}},[_vm._v("პირადი ნომერი (ID)")]),_c('input',{staticClass:"form-control",attrs:{"id":"personalId","type":"number","placeholder":"პირადი ნომერი (ID)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"email"}},[_vm._v("ელ.ფოსტა")]),_c('input',{staticClass:"form-control",attrs:{"id":"email","type":"email","placeholder":"ელ.ფოსტა"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5",attrs:{"for":"position"}},[_vm._v("თანამდებობა")]),_c('input',{staticClass:"form-control",attrs:{"id":"position","type":"email","placeholder":"თანამდებობა"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"h5",attrs:{"for":"email"}},[_vm._v("ჩანიშვნა")]),_c('textarea',{staticClass:"form-control",attrs:{"rows":"7","placeholder":"ჩანიშვნა"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-white text-primary"},[_c('i',{staticClass:"tio-contacts"}),_vm._v(" ახალი საკონტაქტო")])
}]

export { render, staticRenderFns }