<template>
    <div>
         <Header />
         <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
             <div class="content container-fluid">
                 <div class="page-header">
                     <div class="row align-items-end">
                         <div class="col-sm mb-2 mb-sm-0">
                             <h1 class="page-header-title">როლები</h1>
                             <nav aria-label="breadcrumb">
                                 <ol class="breadcrumb breadcrumb-no-gutter">
                                     <li class="breadcrumb-item active" aria-current="page">როლების სია</li>
                                 </ol>
                             </nav>
                         </div>
                         <div class="col-sm-auto align-self-start">
                             <router-link class="btn btn-success" to="/Create_Role">
                                  დამატება
                             </router-link>
                         </div>
                     </div>
                     <!-- End Row -->
                 </div>
 
 
       
                 <div class="row">
                     <!-- Col -->
                     <div class="col-12">
                         <!-- Card -->
                         <div class="card">
                             <div class="panel-body table-responsive">
                                 <table class="table table-bordered table-striped datatable dt-select">
                                     <thead>
                                         <tr>
                                             <th>როლის დასახელება</th>
                                             <th>ფერმიშენები</th>
                                             <th>&nbsp;</th>
                                         </tr>
                                     </thead>
                                     <tbody>
                                         <tr>
                                             <td>users_manage</td>
                                             <td>ფერმიშენები</td>
                                             <td>
                                                 <router-link to="/Edit_Role" class="btn btn-xs btn-success me-1 text-white">
                                                     რედაქტირება
                                                 </router-link>
                                                 <router-link to="" class="btn btn-xs btn-danger">
                                                     წაშლა
                                                 </router-link>
                                             </td>
                                         </tr>
                                     </tbody>
                                 </table>
                             </div>
                         </div>
                         <!-- End Card -->
                      </div>
                     <!-- End Col -->
                 </div>
             </div>
             <!-- End Content -->
 
         
         
         </main>
     </div> 
 </template>
 
 <script>
   import Header from '@/components/Header.vue'
 
 export default {
     name: "Roles",
     components: {
         Header
     }
 }
 </script>
 
 <style>
 
 </style>>