var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main",class:this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'},[_c('div',{staticClass:"content container-fluid"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-sm mb-2 mb-sm-0"},[_c('h1',{staticClass:"page-header-title"},[_vm._v("როლის რედაქტირება")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb breadcrumb-no-gutter"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"breadcrumb-link",attrs:{"to":"/Roles"}},[_vm._v("როლების სია")])],1),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("როლის რედაქტირება")])])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"col-12 form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"permission"}},[_vm._v("მონიშნე ფერმიშენები")]),_c('v-select',{attrs:{"placeholder":"აირჩიე ფერმიშენი","multiple":"","options":[
                                    'users_manage',
                                    'agrement_manage',
                                    'deal_manage',
                                    'clients_manage',
                                    'developer_manage'
                                    ]}}),_c('p',{staticClass:"help-block"}),_c('input',{staticClass:"btn btn-danger",attrs:{"type":"submit","value":"შენახვა"}})],1)])])])])]),_c('div',{staticClass:"footer",class:this.$store.state.showTopMenu== true ? 'collapsed' : ''},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/Roles"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" უკან ")])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"role"}},[_vm._v("დასახელება")]),_c('input',{staticClass:"form-control",attrs:{"placeholder":"როლის დასახელება","name":"role","type":"text","id":"role"}})])
}]

export { render, staticRenderFns }