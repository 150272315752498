<template>
    <router-view />
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
export default ({
    methods: {
        ...mapActions({ addPermission: "setCurrentPermission" }),
    },
    mounted() {
        this.addPermission();
    }
})
</script>
  
<style lang="scss"></style>
  