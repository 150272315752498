<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Deals Overview Row -->
        <div class="row align-items-center" v-if="clients_deal_overview.status != 'client'">


          <div class="col-8">
            <h1 class="page-title">ჩემი დილები</h1>
          </div>

          <div class="col-4 text-end">
            <div class="btn btn-light" data-bs-toggle="offcanvas" data-bs-target="#offcanvasClosingDeals" aria-controls="offcanvasRight"> <span class="d-none d-md-inline-block">ფილტრი</span> <i class="tio-search"></i> </div>
          </div>

          <!-- Closing -->
          <div class="col-12 mt-4">
            <router-link to="/Deals/liked">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-dollar-outlined font-weight-normal display-4 text-custom-success me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.liked }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5 text-custom-success">
                        მოწონებული
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Closing -->

          <!-- Meeting -->
          <div class="col-12 mt-2">
            <router-link to="/Deals/meetings">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-appointment font-weight-normal display-4 text-primary me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.meeting }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5">
                        საპრეზენტაციო
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Meeting -->

          <!-- Offers -->
          <div class="col-12 mt-2">
            <router-link to="/Deals/offers">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-notice-outlined font-weight-normal display-4 text-custom-warning me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.offer }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5">
                        განსახილველი
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Offers -->

          <!-- Nothing to offers -->
          <div class="col-12 mt-2 mb-4">
            <router-link to="/Deals/looking_for">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-autorenew font-weight-normal display-4 text-custom-danger me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.looking_for }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5">
                        შეთავაზების გარეშე
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Nothing to offers -->

          <!-- Won -->
          <div class="col-12 mt-5">
            <router-link to="/Deals/won">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-checkmark-circle-outlined font-weight-normal display-4 text-custom-success me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.won }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5 text-custom-success">
                        გაყიდული
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Won -->

          <!-- Won -->
          <div class="col-12 mt-2">
            <router-link to="/Deals/lost">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-clear-circle-outlined font-weight-normal display-4 text-custom-danger me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.lost }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5 text-custom-danger">
                        დაკარგული
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Won -->

        </div>
        <!-- End Deals Overview Row -->

        <!-- Clients Deals Overview Row -->
        <div class="row align-items-center" v-if="clients_deal_overview.status == 'client'">


          <div class="col-8">
            <h1 class="page-title">ჩემი დილები</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">{{ clients_deal_overview.client_fullname }}</li>
              </ol>
            </nav>
          </div>

          <div class="col-4 text-end">
            <div class="btn btn-light" data-bs-toggle="offcanvas" data-bs-target="#offcanvasClosingDeals" aria-controls="offcanvasRight"> <span class="d-none d-md-inline-block">ფილტრი</span> <i class="tio-search"></i> </div>
          </div>

          <!-- Closing -->
          <div class="col-12 mt-4">
            <router-link :to="'/Deals/liked/'+this.$route.params.id">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-dollar-outlined font-weight-normal display-4 text-custom-success me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.liked }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5 text-custom-success">
                        მოწონებული
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Closing -->

          <!-- Meeting -->
          <div class="col-12 mt-2">
            <router-link :to="'/Deals/meetings/'+this.$route.params.id">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-appointment font-weight-normal display-4 text-primary me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.meeting }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5">
                        საპრეზენტაციო
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Meeting -->

          <!-- Offers -->
          <div class="col-12 mt-2">
            <router-link :to="'/Deals/offers/'+this.$route.params.id">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-notice-outlined font-weight-normal display-4 text-custom-warning me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.offer }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5">
                        განსახილველი
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Offers -->

          <!-- Nothing to offers -->
          <div class="col-12 mt-2 mb-4">
            <router-link :to="'/Deals/looking_for/'+this.$route.params.id">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-autorenew font-weight-normal display-4 text-custom-danger me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.looking_for }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5">
                        შეთავაზების გარეშე
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Nothing to offers -->

          <!-- Won -->
          <div class="col-12 mt-5">
            <router-link :to="'/Deals/won/'+this.$route.params.id">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-checkmark-circle-outlined font-weight-normal display-4 text-custom-success me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.won }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5 text-custom-success">
                        გაყიდული
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Won -->

          <!-- Won -->
          <div class="col-12 mt-2">
            <router-link :to="'/Deals/lost/'+this.$route.params.id">
              <div class="card border-custom-hover card-hover-shadow h-100">
                <div class="card-body">
                  <div class="row align-items-center gx-2">
                    <div class="col">
                      <i class="tio-clear-circle-outlined font-weight-normal display-4 text-custom-danger me-2"></i>
                      <span class="display-4 text-dark">{{ deal_overview.lost }}</span>
                    </div>
                    <div class="col-auto">
                      <span class="h5 text-custom-danger">
                        დაკარგული
                      </span>
                    </div>
                  </div>
                  <!-- End Row -->
                </div>
              </div>
            </router-link>
          </div>
          <!-- End Won -->

        </div>
        <!-- End Clients Deals Overview Row -->

      </div>
      <!-- End Content -->
    </main>
    <!-- End Main -->

    <!-- offcanvas Ritail Clients Filter -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasClosingDeals" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header border-bottom">
        <h4 id="offcanvas-title">ფილტრი</h4>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form class="row">
          <div class="col-12 form-group">
            <label class="h5">დილის ID</label>
            <input class="form-control" type="number" placeholder="დილის ID">
          </div>
          <div class="col-12 form-group">
            <label class="h5">პროდუქტის ტიპი </label>
            <v-select placeholder="აირჩიე პროექტის ტიპი" :options="[
              'ბინები',
              'სასტუმროები',
              'კომერციული ფართები',
              'მიწის ნაკვეთები',
              'სახლები და აგარაკები'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">უძრავი ქონების სტატუსი </label>
            <v-select placeholder="უძრავი ქონების სტატუსი" :options="[
              'ახალი აშენებული',
              'მშენებარე',
              'ძველი აშენებული'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">უძრავი ქონების კონდიცია </label>
            <v-select placeholder="უძრავი ქონების კონდიცია" :options="[
              'თეთრი კარკასი',
              'შავი კარკასი',
              'მწვანე კარკასი',
              'ახალი რემონტი',
              'ძველი რემონტი'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">საძინებლების რაოდენობა </label>
            <v-select multiple placeholder="აირჩიე საძინებლების რაოდენობა" :options="[
              0,
              1,
              2,
              3,
              4
            ]"></v-select>
          </div>
          <div class="col-12">
            <label class="h5">ფასი </label>
          </div>
          <div class="col-6 form-group">
            <label>მინიმალური($)</label>
            <input type="number" class="form-control" placeholder="-დან">
          </div>
          <div class="col-6 form-group">
            <label>მაქსიმალური($)</label>
            <input type="number" class="form-control" placeholder="-მდე">
          </div>
          <div class="col-12">
            <label class="h5">ფართის მოცულობა </label>
          </div>
          <div class="col-6 form-group">
            <label>მინიმალური</label>
            <input type="number" class="form-control" placeholder="-დან">
          </div>
          <div class="col-6 form-group">
            <label>მაქსიმალური</label>
            <input type="number" class="form-control" placeholder="-მდე">
          </div>

          <div class="col-12 form-group">
            <label class="h5">ფილიალის დირექტორი </label>
            <v-select multiple placeholder="აირჩიე ფილიალის დირექტორი" :options="[
              'სალომე ჩემია',
              'ეკა მოლაშვილი',
              'ნინო მდივანი'
            ]"></v-select>
          </div>
          <div class="col-12 form-group">
            <label class="h5">გაყიდვების მენეჯერი </label>
            <v-select multiple placeholder="აირჩიე გაყიდვების მენეჯერი" :options="[
              'მენეჯერი 1',
              'მენეჯერი 2',
              'მენეჯერი 3'
            ]"></v-select>
          </div>

        </form>
      </div>
      <div class="offcanvas-footer p-3 border-top bg-white">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-block btn-primary"><i class="fa fa-search me-1"></i> შესრულება</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-block btn-white"><i class="tio-remove-from-trash text-secondary me-1"></i> გასუფთავება</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End offcanvas Ritail Clients Filter -->

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
  name: "Deals_Overview",
  data() {
    return {
      deal_overview: [
        {
          liked: '',
          meeting: '',
          offer: '',
          looking_for: '',
          won: '',
          lost: ''
        }
      ],
      clients_deal_overview: [
        {
          liked: '',
          meeting: '',
          offer: '',
          looking_for: '',
          won: '',
          lost: ''
        }
      ]
    }
  },
  props: ['message'],
  components: {
    Header
  },
  methods: {
    dealOverview() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/deal/getOverview', config)
        .then((res) => {
          this.deal_overview = res.data

        })
        .catch((error) => {
          var error_response = error.response.data;
          if (error.response.status == 422) {
            this.setErrors(error.response.data.errors);
          } else {
            this.setErrors(error.response.data.message);
          }
        });
    },
    clientsDealsOverview() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/count/deals/'+this.$route.params.id, config)
        .then((res) => {
          this.clients_deal_overview = res.data

        })
        .catch((error) => {
          var error_response = error.response.data;
          if (error.response.status == 422) {
            this.setErrors(error.response.data.errors);
          } else {
            this.setErrors(error.response.data.message);
          }
        });
    },
    setErrors(errors) {
      console.log(errors);
    }
  },
  mounted() {
    this.dealOverview();
    
    if(this.$route.params.id) {
      this.clientsDealsOverview();
    }
  }
}
</script>

<style scoped>
.border-custom-hover {
  border: 1px solid #d5d5d5 !important;
  transition: 0.3s;
}
.border-custom-hover:hover {
  border: 1px solid #727a86 !important;
}
</style>