import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
/* Authorization */
import Signin from '../views/auth/Signin.vue'
import Signup from '../views/auth/Signup.vue'
import Recovery from '../views/auth/Recovery.vue'
/* Academy */
import Academy from '../views/Academy/Academy.vue'
import CreateTest from '../views/Academy/Testing/CreateTest.vue'
import Videos from '../views/Academy/Video'
import VideoMotivation from '../views/Academy/Video/VideoMotivation.vue'
import AddVideo from '../views/Academy/Video/AddVideo.vue'
import Rules from '../views/Academy/Rules'
import Rule from '../views/Academy/Rules/Rule.vue'
import AddRule from '../views/Academy/Rules/AddRule.vue'
import Faq from '../views/Academy/Faq'
import AddFaq from '../views/Academy/Faq/AddFaq.vue'
import AcademyTest from '../views/Academy/Testing/AcademyTest.vue'
import SentTest from '../views/Academy/Testing/SentTest.vue'
import Mytests from '../views/Academy/Testing/Mytests.vue'
import Testing from '../views/Academy/Testing/Testing.vue'
/* Clients */
import RetailClients from '../views/Clients/Retail_Clients/index.vue'
import RetailClientDetails from '../views/Clients/Retail_Clients/Retail_Client_Details.vue'
import CorporateClients from '../views/Clients/Corporate_Clients/index.vue'
import CorporateClientDetails from '../views/Clients/Corporate_Clients/Corporate_Client_Details.vue'
/* Deals */
import DealsOverview from '../views/Deals/index.vue'
import Deals from '../views/Deals/Deal_Steps_Details/Deals.vue'
import Details from '../views/Deals/Deal_Steps_Details/Deal_Details/Details.vue'
import Offers_Details from '../views/Deals/Deal_Steps_Details/Deal_Details/Offers_Details.vue'
import Retail_Client_Information from '../views/Deals/Create_Deals/Open_Retail_Deal/Retail_Client_Information.vue'
import Open_Retail_Deal from '../views/Deals/Create_Deals/Open_Retail_Deal/Open_Retail_Deal.vue'
import Finish_Retail_Deal from '../views/Deals/Create_Deals/Open_Retail_Deal/Finish_Retail_Deal.vue'
import Corporate_Client_Information from '../views/Deals/Create_Deals/Open_Corporate_Deal/Corporate_Client_Information.vue'

// deal edit
import Open_Retail_Deal_edit from '../views/Deals/Create_Deals/Open_Retail_Deal/edit/Open_Retail_Deal_edit.vue'
import Finish_Retail_Deal_edit from '../views/Deals/Create_Deals/Open_Retail_Deal/edit/Finish_Retail_Deal_edit.vue'
import Retail_Client_Information_edit from '../views/Deals/Create_Deals/Open_Retail_Deal/edit/Retail_Client_Information_edit.vue'
// client edit
import Client_edit from '../views/Deals/Create_Deals/Open_Retail_Deal/client_edit/edit.vue'


import Corporate_Client_Identification from '../views/Deals/Create_Deals/Open_Corporate_Deal/Corporate_Client_Identification.vue'
import Open_Corporate_Deal from '../views/Deals/Create_Deals/Open_Corporate_Deal/Open_Corporate_Deal.vue'
import Finish_Corporate_Deal from '../views/Deals/Create_Deals/Open_Corporate_Deal/Finish_Corporate_Deal.vue'
/*Types*/
import Region from '../views/types/Region/Region.vue'
import District from '../views/types/District/District.vue'
import Address from '../views/types/Address/Address.vue'
import AddRegion from '../views/types/Region/AddRegion.vue'
import AddAddress from '../views/types/Address/AddAddress.vue'
import AddDistrict from '../views/types/District/AddDistrict.vue'
import EditRegion from '../views/types/Region/EditRegion.vue'
import EditAddress from '../views/types/Address/EditAddress.vue'
import EditDistrict from '../views/types/District/EditDistrict.vue'

/* users manage */
import users from '../views/Users/index.vue'
import Create from '../views/Users/Create.vue'
import edit_user from '../views/Users/edit/edit.vue'
import Permission from '../views/Users/permission/Permission.vue'
import create_permission from '../views/Users/permission/Create.vue'
import edit_permission from '../views/Users/permission/edit/edit.vue'
import roles from '../views/Users/roles/Roles.vue'
import Create_Role from '../views/Users/roles/Create.vue'
import Edit_Role from '../views/Users/roles/edit/edit.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  /* Authorization */
  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/Recovery',
    name: 'Recovery',
    component: Recovery
  },
  /* user list */
  {
    path: '/users',
    name: 'users',
    component: users
  },
  {
    path: '/Create',
    name: 'Create',
    component: Create
  },
  {
    path: '/edit_user/:id',
    name: 'edit_user',
    component: edit_user
  },
  {
    path: '/Permission',
    name: 'Permission',
    component: Permission
  },
  {
    path: '/create_permission',
    name: 'create_permission',
    component: create_permission
  },
  {
    path: '/edit_permission',
    name: 'edit_permission',
    component: edit_permission
  },
  {
    path: '/roles',
    name: 'roles',
    component: roles
  },
  {
    path: '/Create_Role',
    name: 'Create_Role',
    component: Create_Role
  },
  {
    path: '/Edit_Role',
    name: 'Edit_Role',
    component: Edit_Role
  },
  /* Academy */
  {
    path: '/Academy',
    name: 'Academy',
    component: Academy
  },
  {
    path: '/CreateTest',
    name: 'CreateTest',
    component: CreateTest
  },
  {
    path: '/Videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/VideoMotivation',
    name: 'VideoMotivation',
    component: VideoMotivation
  },
  {
    path: '/AddVideo',
    name: 'AddVideo',
    component: AddVideo
  },
  {
    path: '/Rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/Rule',
    name: 'Rule',
    component: Rule
  },
  {
    path: '/AddRule',
    name: 'AddRule',
    component: AddRule
  },
  {
    path: '/Faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/AddFaq',
    name: 'AddFaq',
    component: AddFaq
  },
  {
    path: '/AcademyTest',
    name: 'AcademyTest',
    component: AcademyTest
  },
  {
    path: '/SentTest',
    name: 'SentTest',
    component: SentTest
  },
  {
    path: '/Mytests',
    name: 'Mytests',
    component: Mytests
  },
  {
    path: '/Testing',
    name: 'Testing',
    component: Testing
  },
    /* Clients */
  {
    path: '/Retail_Clients',
    name: 'RetailClients',
    component: RetailClients
  },
  {
    path: '/Retail_Client_Details/:id',
    name: 'RetailClientDetails',
    component: RetailClientDetails
  },
  {
    path: '/Corporate_Clients',
    name: 'CorporateClients',
    component: CorporateClients
  },
  {
    path: '/Corporate_Client_Details',
    name: 'CorporateClientDetails',
    component: CorporateClientDetails
  },
  /* Deals */
  {
    path: '/Deals_Overview/:id?',
    name: 'DealsOverview',
    component: DealsOverview
  },
  {
    path: '/Deals/:param/:id?',
    name: 'Deals',
    component: Deals
  },
  {
    path: '/Details/:addition/:status/:id',
    name: 'Details',
    component: Details
  },
  {
    path: '/Offers_Details/:addition/:status/:id',
    name: 'Offers_Details',
    component: Offers_Details
  },
  {
    path: '/Retail_Client_Information',
    name: 'Retail_Client_Information',
    component: Retail_Client_Information
  },
  {
    path: '/Open_Retail_Deal',
    name: 'Open_Retail_Deal',
    component: Open_Retail_Deal
  },
  {
    path: '/Finish_Retail_Deal',
    name: 'Finish_Retail_Deal',
    component: Finish_Retail_Deal
  },


  {
    path: '/Retail_Client_Information_edit/:id',
    name: 'Retail_Client_Information_edit',
    component: Retail_Client_Information_edit
  },
  {
    path: '/Open_Retail_Deal_edit/:id',
    name: 'Open_Retail_Deal_edit',
    component: Open_Retail_Deal_edit
  },
  {
    path: '/Finish_Retail_Deal_edit/:id',
    name: 'Finish_Retail_Deal_edit',
    component: Finish_Retail_Deal_edit
  },

  
  // client edit
  {
    path: '/Client_edit/:id',
    name: 'Client_edit',
    component: Client_edit
  },


  {
    path: '/Corporate_Client_Information',
    name: 'Corporate_Client_Information',
    component: Corporate_Client_Information
  },
  {
    path: '/Corporate_Client_Identification',
    name: 'Corporate_Client_Identification',
    component: Corporate_Client_Identification
  },
  {
    path: '/Open_Corporate_Deal',
    name: 'Open_Corporate_Deal',
    component: Open_Corporate_Deal
  },
  {
    path: '/Finish_Corporate_Deal',
    name: 'Finish_Corporate_Deal',
    component: Finish_Corporate_Deal
  },
/*Types*/
  {
    path: '/Region',
    name: 'Region',
    component: Region

  },
  {
    path: '/District',
    name: 'District',
    component: District

  },
  {
    path: '/Address',
    name: 'Address',
    component: Address

  },
  {
    path: '/AddRegion',
    name: 'AddRegion',
    component: AddRegion

  },
  {
    path: '/AddAddress',
    name: 'AddAddress',
    component: AddAddress

  },
  {
    path: '/AddDistrict',
    name: 'AddDistrict',
    component: AddDistrict

  },
  {
    path: '/EditRegion',
    name: 'EditRegion',
    component: EditRegion

  },
  {
    path: '/EditAddress',
    name: 'EditAddress',
    component: EditAddress

  },
  {
    path: '/EditDistrict',
    name: 'EditDistrict',
    component: EditDistrict

  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {


  if (to.name === 'Signin' || to.name==='Signup' || to.name==='Recovery' || to.name==='PasswordReset') {
    if(localStorage.getItem('user')){
      router.push('/')
    }else{
      next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    }
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (localStorage.getItem('user')) {

    next() // i'm logged in. carry on
  } else {
    if(to.name==='Signup'){
      next({ name: 'Signup' }) // always put your redirect as the default case
    }else{
      next({ name: 'Signin' }) // always put your redirect as the default case
    }

  }
})

export default router