<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">
          <div class="col-12 mb-4">
            <h1 class="page-title">წესები და ბრძანებები</h1>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-header h5 bg-transparent">
                დიქეი კაპიტალის საოპერაციო საქმიანობა (V9)
              </div>
              <div class="card-body">
                <div class="form-row">

                  <!-- Col -->
                  <div class="form-group col-12">
                    <iframe width="100%" height="500" src="https://dkcrm.ge/uploads/rules/b207606d7dc51be6e.pdf" title="დიქეი კაპიტალის საოპერაციო საქმიანობა (V9)">
                    </iframe>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <div class="card shadow-none">
                      <div class="card-header bg-custom-dark text-white">
                        კითხვები: 5
                      </div>
                      <div class="card-body p-2">
                        <ul class="list-group list-group-flush list-group-no-gutters">

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-12 d-flex">
                                    <h5 class="mb-0 me-1">1:</h5>
                                    <span class="font-size-sm">როგორ ვიწყებთ დიქეი კაპიტალში პირველ სამუშაო დღეს გამოცდის ჩაბარების შემდეგ?</span>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-12 d-flex">
                                    <h5 class="mb-0 me-1">2:</h5>
                                    <span class="font-size-sm">როგორ ვიწყებთ სამუშაო დღეს დიქეი კაპიტალში?</span>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-12 d-flex">
                                    <h5 class="mb-0 me-1">3:</h5>
                                    <span class="font-size-sm">რატომ ჯობია კლიენტზე ფოკუსირება?</span>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-12 d-flex">
                                    <h5 class="mb-0 me-1">4:</h5>
                                    <span class="font-size-sm">რა შედეგის მოტანა შეუძლია 1 კლიენტს კონსულტანტისთვის და მასზე ფოკუსირებას?</span>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-12 d-flex">
                                    <h5 class="mb-0 me-1">5:</h5>
                                    <span class="font-size-sm">რა გავაკეთოთ როდესაც კლიენტს უნდა დეველოპერისგან უძრავი ქონების შეძენა და ჩვენი პარტნიორი დეველიპერული კომპანიის შეთავაზებები ვერ მივყიდეთ?</span>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                        </ul>

                      </div>
                    </div>
                  </div>
                  <!-- End Col -->

                </div>
              </div>
              <div class="card-footer text-end">
                <a href="AddVideoMotivation" class="btn btn-white"><i class="tio-edit"></i> შეცვლა</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Rules">
              <a href=""><i class="fa fa-arrow-left"></i> უკან</a>
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from '../../../components/Header.vue'

export default {
  name: "Rule",
  components: {
    Header
  }
}
</script>

<style scoped>

</style>