<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <div class="row">
          <!-- Col -->
          <div class="col-12 mb-4">
            <h1 class="page-title">ტესტის შექმნა</h1>
          </div>
          <!-- End Col -->
          <div class="col-12 mb-5">
            <div class="d-block overflow-auto text-center mb-3">
              <ul class="nav nav-bgcolor d-inline-flex nav-pills mb-3 flex-nowrap" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ყველა</button>
                </li>
                <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">თბილისი</button>
              </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">ბათუმი</button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <!-- tab-pane -->
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <div class="accordion-header botb" id="headingOne">
                      <h2 class="d-flex align-items-center border-bottom">
                        <div class="form-check form-switch ms-2 me-1">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>
                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                        </div>
                        <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          <i class="tio-group-junior"></i>&nbsp;სალომე ჩემია
                        </div>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კონსულტანტები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked>
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header botb" id="headingTwo">
                      <h2 class="d-flex align-items-center border-bottom">
                        <div class="form-check form-switch ms-2 me-1">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                        </div>
                        <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="cursor: pointer;">
                          <i class="tio-group-junior"></i>&nbsp;ეკა მოლაშვილი
                        </div>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კონსულტანტები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header botb" id="headingThree">
                      <h2 class="d-flex align-items-center border-bottom">
                        <div class="form-check form-switch ms-2 me-1">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                        </div>
                        <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style="cursor: pointer;">
                          <i class="tio-group-junior"></i>&nbsp;ნინო მალანია
                        </div>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კონსულტანტები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header botb" id="headingFour">
                      <h2 class="d-flex align-items-center border-bottom">
                        <div class="form-check form-switch ms-2 me-1">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                        </div>
                        <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style="cursor: pointer;">
                          <i class="tio-group-junior"></i>&nbsp;ნინო მდივანი
                        </div>
                      </h2>
                      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კონსულტანტები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>სახელი გვარი</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End tab-pane -->

              <!-- tab-pane -->
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="accordion" id="accordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header botb" id="headingOne1">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                        <i class="tio-group-junior"></i>&nbsp;ეკა მოლაშვილი
                      </button>
                    </h2>
                    <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                      <div class="accordion-body">
                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header botb" id="headingTwo2" >
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                        <i class="tio-group-junior"></i>&nbsp;ნინო მალანია
                      </button>
                    </h2>
                    <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample1">
                      <div class="accordion-body">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header botb" id="headingThree3">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
                        <i class="tio-group-junior"></i>&nbsp;ნინო მდივანი
                      </button>
                    </h2>
                    <div id="collapseThree3" class="accordion-collapse collapse" aria-labelledby="headingThree3" data-bs-parent="#accordionExample1">
                      <div class="accordion-body">
                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End tab-pane -->

              <!-- tab-pane -->
              <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="accordion" id="accordionExampleB">
                  <div class="accordion-item">
                    <h2 class="accordion-header botb" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <i class="tio-group-junior"></i>&nbsp;სალომე ჩემია
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExampleB">
                      <div class="accordion-body">
                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header botb" id="headingTwo" >
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <i class="tio-group-junior"></i>&nbsp;მარიამ ბარამიძე
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleB">
                      <div class="accordion-body">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header botb" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <i class="tio-group-junior"></i>&nbsp;ნათია ცეცხლაძე
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExampleB">
                      <div class="accordion-body">
                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End tab-pane -->
            </div>
          </div>
          <!-- End Col -->
          <div class="col-12 mb-5">
            <div class="d-block overflow-auto text-center mb-3">
              <ul class="nav nav-bgcolor d-inline-flex nav-pills mb-3 flex-nowrap" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link text-custom-danger active" id="video" data-bs-toggle="pill" data-bs-target="#video-tab" type="button" role="tab" aria-controls="video-tab" aria-selected="true"><i class="tio-play-circle-outlined"></i> ვიდეო</a>
                </li>
                <li class="nav-item" role="presentation">
                <a class="nav-link text-dark" id="rules" data-bs-toggle="pill" data-bs-target="#rules-tab" type="button" role="tab" aria-controls="rules-tab" aria-selected="false"><i class="tio-document-text"></i> ბრძანება</a>
              </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link text-custom-success" id="faq" data-bs-toggle="pill" data-bs-target="#faq-tab" type="button" role="tab" aria-controls="faq-tab" aria-selected="false"><i class="tio-help"></i> ხ.დ.კ</a>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <!-- tab-pane -->
              <div class="tab-pane fade show active" id="video-tab" role="tabpanel" aria-labelledby="video">
                <div class="accordion" id="accordionVideo">
                  <div class="accordion-item">
                    <div class="accordion-header video-bot" id="headingVideoOne">
                      <h2>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVideoOne" aria-expanded="false" aria-controls="collapseVideoOne">
                          <i class="tio-survey"></i>&nbsp;მოტივაცია #1
                        </button>
                      </h2>
                      <div id="collapseVideoOne" class="accordion-collapse collapse" aria-labelledby="headingVideoOne" data-bs-parent="#accordionVideo">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კითხვები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault" checked>
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ ვიწყებთ დიქეი კაპიტალში პირველ სამუშაო დღეს გამოცდის ჩაბარების შემდეგ?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ ვიწყებთ სამუშაო დღეს დიქეი კაპიტალში?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რატომ ჯობია კლიენტზე ფოკუსირება?</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header video-bot" id="headingVideoTwo">
                      <h2>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVideoTwo" aria-expanded="false" aria-controls="collapseVideoTwo">
                          <i class="tio-survey"></i>&nbsp;მოტივაცია #2
                        </button>
                      </h2>
                      <div id="collapseVideoTwo" class="accordion-collapse collapse" aria-labelledby="headingVideoTwo" data-bs-parent="#accordionVideo">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კითხვები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>შეგვიძლია თუ არა უძრავი ქონების პორტფელში დამატება შესაბამისი კლიენტის არ ყოლის შემთხვევაში? რატომ?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ უნდა მოვამზადოთ შეხვედრისთვის ლენდლორდი და კლიენტი, რაზე უნდა შევთანმხდეთ წინასწარ?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რა გავაკეთოთ როცა უძრავი ქონების მეპატრონეს არ უნდა ჩვენთან თანამშრომლობა?</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  <div class="accordion-item">
                    <div class="accordion-header video-bot" id="headingVideoThree">
                      <h2>
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVideoThree" aria-expanded="false" aria-controls="collapseVideoThree">
                        <i class="tio-survey"></i>&nbsp;მოტივაცია #3
                      </button>
                    </h2>
                      <div id="collapseVideoThree" class="accordion-collapse collapse" aria-labelledby="headingVideoThree" data-bs-parent="#accordionVideo">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კითხვები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რა გავაკეთოთ როცა ლენდლორდი ან კლიენტი ზერელედ “ფეხებზე მკიდიხარ” სტრატეგიით გვესაუბრება</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ მოვიქცეთ თუ კლიენტი გვეუბნება, რომ ნანახი აქვს ყველა სახის უძრავი ქონება, რომელიც არის განთავსებული საიტებზე?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-danger" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რა გავაკეთოთ თუ კლიენტს არ მოსწონს უძრავი ქონება სურათებში?</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End tab-pane -->

              <!-- tab-pane -->
              <div class="tab-pane fade" id="rules-tab" role="tabpanel" aria-labelledby="rules">
                <div class="accordion" id="accordionRules">
                  <div class="accordion-item">
                    <div class="accordion-header rules-bot" id="headingRulesOne">
                      <h2>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRulesOne" aria-expanded="false" aria-controls="collapseRulesOne">
                          <i class="tio-survey"></i>&nbsp;დიქეი კაპიტალის საოპერაციო საქმიანობა (V9)
                        </button>
                      </h2>
                      <div id="collapseRulesOne" class="accordion-collapse collapse" aria-labelledby="headingRulesOne" data-bs-parent="#accordionRules">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კითხვები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ ვიწყებთ დიქეი კაპიტალში პირველ სამუშაო დღეს გამოცდის ჩაბარების შემდეგ?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ ვიწყებთ სამუშაო დღეს დიქეი კაპიტალში?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რატომ ჯობია კლიენტზე ფოკუსირება?</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header rules-bot" id="headingRulesTwo">
                      <h2>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRulesTwo" aria-expanded="false" aria-controls="collapseRulesTwo">
                          <i class="tio-survey"></i>&nbsp;დიქეი კაპიტალის მართვის ინსტრუქცია (V3)
                        </button>
                      </h2>
                      <div id="collapseRulesTwo" class="accordion-collapse collapse" aria-labelledby="headingRulesTwo" data-bs-parent="#accordionRules">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კითხვები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>შეგვიძლია თუ არა უძრავი ქონების პორტფელში დამატება შესაბამისი კლიენტის არ ყოლის შემთხვევაში? რატომ?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ უნდა მოვამზადოთ შეხვედრისთვის ლენდლორდი და კლიენტი, რაზე უნდა შევთანმხდეთ წინასწარ?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რა გავაკეთოთ როცა უძრავი ქონების მეპატრონეს არ უნდა ჩვენთან თანამშრომლობა?</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header rules-bot" id="headingRulesThree">
                      <h2>
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRulesThree" aria-expanded="false" aria-controls="collapseRulesThree">
                          <i class="tio-survey"></i>&nbsp;ინფორმაცია ძველი მშენებლობების შესახებ
                        </button>
                      </h2>
                      <div id="collapseRulesThree" class="accordion-collapse collapse" aria-labelledby="headingRulesThree" data-bs-parent="#accordionRules">
                        <div class="accordion-body">
                          <div class="card rounded-0 border-0">
                            <div class="card-header ps-2">
                              <span class="card-title">კითხვები: 3</span>
                            </div>
                            <div class="card-body p-0">
                              <div class="row">
                                <div class="col-12">
                                  <table class="table">
                                    <tbody>
                                    <tr>
                                      <td style="width: 50px;">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რა გავაკეთოთ როცა ლენდლორდი ან კლიენტი ზერელედ “ფეხებზე მკიდიხარ” სტრატეგიით გვესაუბრება</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>როგორ მოვიქცეთ თუ კლიენტი გვეუბნება, რომ ნანახი აქვს ყველა სახის უძრავი ქონება, რომელიც არის განთავსებული საიტებზე?</span></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input toggle-switch-label-dark" type="checkbox" id="flexSwitchCheckDefault">
                                          <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                                        </div>
                                      </td>
                                      <td><span>რა გავაკეთოთ თუ კლიენტს არ მოსწონს უძრავი ქონება სურათებში?</span></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End tab-pane -->

              <!-- tab-pane -->
              <div class="tab-pane fade" id="faq-tab" role="tabpanel" aria-labelledby="faq">
                <div class="card">
                  <div class="card-header bg-transparent ps-2">
                    <span class="card-title">კითხვები: 3</span>
                  </div>
                  <div class="card-body p-0">
                    <div class="row">
                      <div class="col-12">
                        <table class="table">
                          <tbody>
                          <tr>
                            <td style="width: 50px;">
                              <div class="form-check form-switch">
                                <input class="form-check-input toggle-switch-label-success" type="checkbox" id="flexSwitchCheckDefault">
                                <label class="form-check-label " for="flexSwitchCheckDefault"></label>
                              </div>
                            </td>
                            <td><span>როგორ ვიწყებთ დიქეი კაპიტალში პირველ სამუშაო დღეს გამოცდის ჩაბარების შემდეგ?</span></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="form-check form-switch">
                                <input class="form-check-input toggle-switch-label-success" type="checkbox" id="flexSwitchCheckDefault">
                                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                              </div>
                            </td>
                            <td><span>როგორ ვიწყებთ სამუშაო დღეს დიქეი კაპიტალში?</span></td>
                          </tr>
                          <tr>
                            <td>
                              <div class="form-check form-switch">
                                <input class="form-check-input toggle-switch-label-success" type="checkbox" id="flexSwitchCheckDefault">
                                <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                              </div>
                            </td>
                            <td><span>რატომ ჯობია კლიენტზე ფოკუსირება?</span></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End tab-pane -->
            </div>
          </div>
          <!-- End Col -->
          <div class="col-12 mb-5">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="input-custom-label" for="timer">ერთი კითხვის დრო</label>
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text bg-transparent px-3" id="timer"><i class="tio-timer"></i></span>
                      <input type="text" class="form-control" placeholder="ერთი კითხვის დრო" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="input-custom-label">გაგზავნის თარიღი</label><br>
                    <date-picker class="form-control p-0" v-model="time1" valueType="format" id="calendar" placeholder="როდის გაიგზავნოს ტესტი?"></date-picker>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between align-items-center bg-transparent p-3">
                <h2>კითხვების რაოდენობა: 20</h2>
                <div>
                  <button class="btn btn-white me-1">გასუფთავება</button>
                  <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="tio-send-outlined"></i> გაგზავნა</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
          <div class="row">
            <div class="col">
              <router-link to="/Academy">
                <a href="Academy"><i class="fa fa-arrow-left"></i> უკან</a>
              </router-link>
            </div>
          </div>
        </div>
        <!-- End Footer -->
      </div>
      <!-- End Content -->
    </main>
    <!-- End Main -->

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center p-5">
            <h1 class="test-success text-custom-success"><i class="tio-checkmark-circle-outlined"></i></h1>
            ტესტი წარმატებით გაიგზავნა
          </div>
          <div class="modal-footer">
            <a href="CreateTest" type="button" class="btn btn-custom-success">დაბრუნდება</a>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import DatePicker from 'vue2-datepicker';

export default {
  name: "CreateTest",
  data () {
    return {
      showTopMenu: false,
      downB: true,
      time1: null
    }
  },
  components: {
    Header,
    DatePicker
  },
  mounted() {
    this.showTopMenu=localStorage.getItem('showTopMenu');
  }
}
</script>

<style scoped>
@import "../../../assets/css/tio-icon.css";

.nav-bgcolor {
  background-color: #f8fafd!important;
  border-radius: 6.1875rem!important;
  padding: 0.25rem 0.25rem;
}
.nav-pills .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgb(140 152 164 / 25%);
  border-radius: 6.1875rem;
  font-size: .8125rem;
}
.nav-pills .nav-link {
  font-size: .8125rem;
  color: #677788;
}
.nav-pills .nav-link:hover {
  color: #377dff;
}
.accordion-button {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.4;
}
.accordion-button::after {
  content: "\e904";
  font-family: 'The-Icon-of' !important;
  font-size: 1.125em;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: none;
}
.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  outline: none!important;
}
.accordion-button:not(.collapsed)::after {
  content: "\e954";
  font-family: 'The-Icon-of' !important;
  font-size: 1.125em;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: none;
  transform: rotate(0deg);
}
.tab-content {
  border-radius: 0.75rem;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.75rem - 1px) !important;
  border-top-right-radius: calc(0.75rem - 1px) !important;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.accordion-body {
  padding: 0px;
}
h2 {
  margin-bottom: 0!important;
}
.botb {
  border-bottom: 3px solid #ededed;
}
.botb:hover {
  border-bottom: 3px solid #377dff;
}
.accordion-item:last-of-type .botb {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.accordion-item:last-of-type .card {
  border-bottom-left-radius: 0.75rem!important;
  border-bottom-right-radius: 0.75rem!important;
}
.card .card-title {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.4;
}
td span {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.4;
}

.rules-bot {
  border-bottom: 3px solid #ededed;
}
.rules-bot:hover {
  border-bottom: 3px solid #132144!important;
}
.accordion-item:last-of-type .rules-bot {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.table {
  margin-bottom: 0px;
}
.table tbody tr:last-of-type {
  border-bottom: 1px solid transparent;
}
.accordion-button
{
  cursor: pointer;
  border-bottom: 0!important;
  box-shadow: none!important;
}
</style>

<style>
.test-success {
  font-size: 65px!important;
}
</style>