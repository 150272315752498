  <template>
    <div>
      <Header/>
      <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
        <div class="content container-fluid academy">
          <!-- Row -->
          <div class="row align-items-center">
            <div class="col-12 mb-4">
              <h1 class="page-title">კითხვის დამატება</h1>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="form-row">

                    <!-- Col -->
                    <div class="form-group col-12">
                      <label class="input-label h5">კითხვის სათაური</label>
                      <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text bg-transparent px-3 text-custom-success" id="timer"><i class="tio-help-outlined"></i></span>
                        <input type="text" class="form-control" placeholder="კითხვის სათაური" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                      </div>
                    </div>
                    <!-- End Col -->
                    <div class="form-group col-12">
                      <label class="input-label h5">კითხვის პასუხი</label>
                      <quill-editor
                          v-model="content"
                          ref="myQuillEditor"
                          :options="editorOption"
                      />
                    </div>
                    <!-- End Col -->

                  </div>
                </div>
                <div class="card-footer text-end p-3">
                  <router-link to="/Faq">
                    <a href="/Faq" class="btn btn-white me-1"><i class="tio-back-ui"></i> გაუქმება</a>
                  </router-link>
                  <button class="btn btn-custom-success"><i class="tio-save"></i> შენახვა</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Content -->
      </main>
      <!-- End Main -->
    </div>
  </template>

  <script>
    import Header from '../../../components/Header.vue'
    import 'quill/dist/quill.snow.css'
    import { quillEditor } from 'vue-quill-editor'

    export default {
      name: "AddFaq",
      components: {
        Header,
        quillEditor
      },
      data(){
        return {
          editorOption: {
            debug: 'info',
            placeholder: 'ჩაწერე ტექსტი',
            readOnly: true,
            theme: 'snow'
          }
        }
      },
      watch: {
        content (val) {
          this.delta = this.$refs.myQuillEditor.quill._getContent()
        }
      },
    }
  </script>

  <style scoped>

  </style>