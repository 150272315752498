<template>
  <div>
    <div class="testing"></div>
    <Header />
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">
          <!-- Col -->
          <div class="col-12 mb-4 ">
            <div class="card">
              <div class="card-header bg-transparent">

                <h1 class="page-title">ტესტირება</h1>
                <small class="text-muted">კითხვების რაოდენობა: 10</small>
              </div>
              <div class="card-header">
                <span class="h5">როგორ ვიწყებთ დიქეი კაპიტალში პირველ სამუშაო დღეს გამოცდის ჩაბარების შემდეგ?</span>
              </div>
              <div class="card-body">
                <vue-editor
                    id="editor"
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    v-model="htmlForEditor"
                >
                </vue-editor>
              </div>
              <div class="card-footer d-flex justify-content-between align-items-center bg-transparent">
                <div class="h5">
                  <i class="tio-timer me-1"></i>
                  <span>2</span>
                </div>
                <a href="#" class="btn btn-primary">შემდეგ</a>
              </div>
            </div>
          </div>
          <!-- End Col -->

        </div>
      </div>
      <!-- End Content -->
    </main>
    <!-- End Main -->

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">ბრძანების წაშლა</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ ვიდეო ბრძანების წაშლა?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-bs-dismiss="modal">გაუქმება</button>
            <button type="button" class="btn btn-custom-danger">თანხმობა</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Header from '../../../components/Header.vue'

export default {
  name: "Faq",
  components: {
    Header,
    VueEditor
  },
  data() {
    return {
      htmlForEditor: ""
    }
  }
}
</script>

<style scoped>
.testing {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.51);
  cursor: no-drop;
  z-index: 101;
}
.main {
  position:relative;
  z-index: 102;
}
</style>