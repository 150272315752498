<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title mb-1">თიბისი ბანკი</h1>
          </div>

          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/Corporate_Clients">კორპორატიული კლიენტები</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">დეტალები</li>
              </ol>
            </nav>
          </div>

          <!-- Corporate Clients Details -->
          <div class="col-12 mt-3">
            <div class="card">
              <div class="card-header bg-custom-primary d-flex justify-content-between text-white">
                <span class="font-weigt-bold ">კლიენტის მონაცემები</span>
                <span class="font-weigt-bold">ID: 50</span>
              </div>
              <div class="card-body">
                <table class="table table-borderless">
                  <tbody>
                  <tr>
                    <td class="d-flex flex-column pb-3">
                      <span class="font-weigt-bold mb-2">კომპანიის დასახელება</span>
                      თიბისი ბანკი
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">საიდენტიფიკაციო კოდი</span>
                      5678909090
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">ელ.ფოსტა</span>
                      tbcbank@tbc.ge
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">საკონტაქტო პირი + ნომერი</span>
                      <span class="text-primary"><i class="tio-arrow-right-circle-outlined d-inline-block text-dark"></i> არჩილ აბაშიძე - 555 000 000</span>
                      გარე გაყიდვების ქსელის განვითარების დეპატრამენტის უფროსი
                      <br>
                      <br>
                      <span class="text-primary"><i class="tio-arrow-right-circle-outlined d-inline-block text-dark"></i> არჩილ აბაშიძე - 555 000 000</span>
                      გარე გაყიდვების ქსელის განვითარების დეპატრამენტის უფროსი
                      <br>
                      <br>
                      <span class="text-primary"><i class="tio-arrow-right-circle-outlined d-inline-block text-dark"></i> არჩილ აბაშიძე - 555 000 000</span>
                      გარე გაყიდვების ქსელის განვითარების დეპატრამენტის უფროსი
                      <br>
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">კლიენტის პირველადი რეგისტრაციის ავტორი</span>
                      სალომე ჩემია
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">პირველადი რეგისტრაციის თარიღი</span>
                      01.01.2022
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-3">
                      <a href="client-data.html" class="btn btn-secondary me-1 mb-2"><i class="tio-edit"></i> შეცვლა</a>
                      <button class="btn btn-white me-1 mb-2" data-bs-toggle="modal" data-bs-target="#clientHistory"><span class="badge bg-custom-primary pt-1">10</span> სულ დილები</button>
                      <button class="btn btn-white me-1 mb-2" data-bs-toggle="modal" data-bs-target="#clientHistory"><span class="badge bg-custom-success pt-1">10</span> აქტიური დილები</button>
                      <router-link to="/Deals" class="btn btn-primary mb-2"><i class="fa fa-eye"></i> დილების ნახვა</router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- End Corporate Clients Details -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Corporate_Clients">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->

    <!-- Modal -->
    <div class="modal fade" id="clientHistory" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">დილების ისტორია</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">დახურვა</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "Corporate_Client_Details",
  components: {
    Header
  }
}
</script>

<style scoped>

</style>