<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <!-- Deal is't entered -->
      <div v-if="step_1 == null" class="container-fluid my-4">
        <div class="card">
      
          <div class="card-body">
            <router-link to="/Retail_Client_Information" v-if="step_1 == null && step_2 == null" class="btn btn-primary">დაიწყე დილის შევსება</router-link>
          </div>
        </div>
      </div>
      <!-- End Deal is't entered -->
      <div v-if="step_1 != null" class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title">რითეილ დილის დამატება</h1>
          </div>

          <!-- Col -->
          <div class="col-12 mt-4">
            <div class="card">
              <div class="card-header d-flex flex-column flex-md-row align-items-md-center text-dark font-weigt-bold overflow-auto">
                <router-link to="/Retail_Client_Information" class="d-flex align-items-center">
                  <h1 class="me-3 mb-0">01</h1>
                  <h4 class="mb-0 me-3">კლიენტის ინფორმაცია</h4>
                </router-link>
                <router-link to="/Open_Retail_Deal" class="d-flex align-items-center me-3">
                  <h1 class="me-3 mb-0 text-primary">02</h1>
                  <div> <h4 class="mb-0 text-primary">დილის ინფორმაცია</h4> <span class="text-dark" v-b-tooltip.hover title="რითეილ კლიენტი"> {{ step_1.name+" "+step_1.surname }}</span></div>
                </router-link>
                <router-link to="/Finish_Retail_Deal" class="d-flex align-items-center">
                  <h1 class="me-3 mb-0">03</h1>
                  <h4 class="mb-0">დაასრულე დილის გახსნა</h4>
                </router-link>
              </div>
              <div class="card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <!-- Row -->
                  <form v-on:submit.prevent="handleSubmit(submitForm)" @keydown="clearError" class="row">
                    <!-- Col -->
                    <div class="form-group col-12 col-md-6">
                      <ValidationProvider name="product_type" rules="required" v-slot="{ errors }">
                        <label class="h5">პროდუქტის ტიპი</label>
                        <v-select :class="errors[0] ? 'error' : ''" v-model="form.product_type" placeholder="აირჩიე პროდუქტის ტიპი" label="name" :options="product_type">
                        </v-select>
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
                
                    <!-- Col -->
                    <div class="form-group col-12 col-md-6">
                      <ValidationProvider name="real_estate_type" rules="required" v-slot="{ errors }">
                        <label class="h5">უძრავი ქონების ტიპი </label>
                        <v-select :class="errors[0] ? 'error' : ''" v-model="form.real_estate_type" placeholder="აირჩიე უძრავი ქონების ტიპი" label="name" :options="real_estate_type">
                        </v-select>
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
                
                    <!-- Col -->
                    <div class="form-group col-12 col-md-6" v-if="form.real_estate_type.name == 'ბინები' || form.real_estate_type.name == 'სახლები და აგარაკები'">
                      <ValidationProvider name="bedroom" rules="required" v-slot="{ errors }">
                        <label class="h5">საძინებლების რაოდენობა </label>
                        <v-select :class="errors[0] ? 'error' : ''" label="number" v-model="form.bedroom" multiple placeholder="აირჩიე საძინებლების რაოდენობა" :options="[
                           {id: 1, number: '1'},
                          {id: 2, number: '2'},
                          {id: 3, number: '3'},
                          {id: 4, number: '4+'}
                        ]">
                        </v-select>
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
                
                    <!-- Col -->
                    <div class="col-12">
                      <label for="minimum_amount" class="h5">ფასი </label>
                    </div>
                    <div class="form-group col-6">
                      <ValidationProvider name="minimum_amount" :rules="{required: true, integer}" v-slot="{ errors }">
                        <label for="minimum_amount">მინიმალური ($)</label>
                        <input :class="errors[0] ? 'error' : ''" v-model="form.minimum_amount" id="minimum_amount" type="text" class="form-control" placeholder="-დან">
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-6">
                      <ValidationProvider name="maximum_amount" :rules="{required: true, integer}" v-slot="{ errors }">
                        <label for="maximum_amount">მაქსიმალური ($)</label>
                        <input :class="errors[0] ? 'error' : ''" v-model="form.maximum_amount" id="maximum_amount" type="text" class="form-control" placeholder="-მდე">
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
                
                    <!-- Col -->
                    <div class="form-group col-12">
                      <button class="btn btn-primary"><i class="tio-next-ui"></i> შემდეგი</button>
                    </div>
                    <!-- End Col -->
                  </form>
                  <!-- End Row -->
                </ValidationObserver>
              </div>
            </div>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Retail_Client_Information">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import axios from 'axios';
 /* From Validation Massage */
 import { extend } from 'vee-validate/dist/vee-validate.full';
 import { required, integer } from 'vee-validate/dist/rules';
import Header from '@/components/Header.vue'

export default {
  name: "Open_Retail_Deal",
  data() {
    return {
      integer: '',
      errors: {},
      form: {
        product_type: '',
        real_estate_type: '',
        bedroom: '',
        minimum_amount: '',
        maximum_amount: ''
      },
      // Get Product Type Data
      product_type: [],
      // Get Real Estate Type Data
      real_estate_type: [],
      client: '',
      step_1: {},
      step_2: {}
    }
  },
  components: {
    Header
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    submitForm(){
      // alert()
      // window.location.reload();
      // axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/signup', this.form)
      //     .then((res) => {
      //       localStorage.setItem('user',JSON.stringify(res.data))
      //       window.location ='/';
      //     })
      //     .catch((error) => {
      //       var error_response=error.response.data;
      //       if(error.response.status==422){
      //         this.setErrors(error.response.data.errors);
      //       }else{
      //         this.setErrors(error.response.data.message);
      //       }
      //     });

      if(this.form.real_estate_type.id == 2 || this.form.real_estate_type.id == 3 || this.form.real_estate_type.id == 4) {
        this.form.bedroom = '';
      }
      this.$router.push({
        path: '/Finish_Retail_Deal'
      });
      localStorage.setItem('Step_2',JSON.stringify(this.form));
    },
    getStep_2() {
      const step_2 = JSON.parse(localStorage.getItem("Step_2"));
      if(step_2) {
        this.form.product_type = step_2.product_type;
        this.form.real_estate_type = step_2.real_estate_type;
        this.form.bedroom = step_2.bedroom;
        this.form.minimum_amount = step_2.minimum_amount;
        this.form.maximum_amount = step_2.maximum_amount;
      }
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){

      return (fieldName in this.errors);
    },
    clearError(event){

      delete this.errors[event.target.name]
    },
    message() {
      // All Required Field.
      extend('required', {
        ...required,
        message: 'აუცილებელი ველი'
      });
      extend('integer', {
        ...integer,
        message: 'შეიყვანეთ მხოლოდ ციფრები'
      });
    },


    // Product Type
    getProductType() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL+"/auth/products", config)
      .then(response => {
        this.product_type = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    // Real Estate Type
    getRealEstateType() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL+"/auth/realestate/types", config)
      .then(response => {
        this.real_estate_type = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.scrollToTop();
    this.message();
    this.getStep_2();

    // Get Product Type
    this.getProductType();
    // Real Estate Type
    this.getRealEstateType();

    const step_1 = JSON.parse(localStorage.getItem("Step_1"));
    this.step_1 = step_1
    const step_2 = JSON.parse(localStorage.getItem("Step_2"));
    this.step_2 = step_2

    // const step_1 = JSON.parse(localStorage.getItem("Step_1"));
    // step_1.name;
    // step_1.surname;
    // this.client = step_1.name+" "+step_1.surname;
    // console.log(this.client);
  }
}
</script>

<style scoped>
.error {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
}
.error:focus {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}
</style>