<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">
          <div class="col-12">
            <h1 class="page-title">აკადემია</h1>
          </div>

          <!-- Create Test -->
          <div class="col-12 mt-4">
            <router-link to="/CreateTest">
              <a href="CreateTest">
                <!-- Card -->
                <div class="card card-hover-shadow bg-primary text-white border-primary h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-add font-weight-normal display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5 text-white">
                    ტესტის შექმნა
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Create Test -->

          <!-- Consultant's Test -->
          <div class="col-12 mt-4">
            <router-link to="/Mytests">
              <a href="Mytests">
                <!-- Card -->
                <div class="card card-hover-shadow border-primary h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-browser-windows font-weight-normal display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5">
                    ჩემი ტესტები
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Consultant's Test -->

          <!-- Col-1 -->
          <div class="col-12 mt-4">
            <router-link to="/Videos">
              <a href="Videos">
                <!-- Card -->
                <div class="card card-hover-shadow card-border-custom h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-play-circle-outlined font-weight-normal text-danger display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5">
                    სასწავლო ვიდეოები
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Col-1 -->

          <!-- Col-2 -->
          <div class="col-12 mt-2">
            <router-link to="/Rules">
              <a href="Rules">
                <!-- Card -->
                <div class="card card-hover-shadow card-border-rules h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-document-text font-weight-normal text-dark display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5">
                    წესები და ბრძანებები
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Col-2 -->

          <!-- Col-3 -->
          <div class="col-12 mt-2">
            <router-link to="/Faq">
              <a href="Faq">
                <!-- Card -->
                <div class="card card-hover-shadow card-border-faq h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-help font-weight-normal text-custom-success display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5">
                    ხშირად დასმული შეკითხვები
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Col-3 -->

          <!-- Col-4 -->
          <div class="col-12 mt-2">
            <router-link  to="/AcademyTest">
              <a href="AcademyTest">
                <!-- Card -->
                <div class="card card-hover-shadow card-border-tests h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-verified font-weight-normal text-primary display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5">
                    ჩატარებული ტესტები
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Col-4 -->

          <!-- Col-5 -->
          <div class="col-12 mt-2">
            <router-link  to="/SentTest">
              <a href="SentTest">
                <!-- Card -->
                <div class="card card-hover-shadow card-border-tests h-100">
                  <div class="card-body">
                    <div class="row align-items-center gx-2">
                      <div class="col">
                        <i class="tio-send font-weight-normal text-primary display-4"></i>
                      </div>
                      <div class="col-auto">
                  <span class="h5">
                    გაგზავნილი ტესტები
                  </span>
                      </div>
                    </div>
                    <!-- End Row -->
                  </div>
                </div>
                <!-- End Card -->
              </a>
            </router-link>
          </div>
          <!-- End Col-5 -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  name: "Academy",
  data () {
    return {
      showTopMenu: false
    }
  },
  components: {
    Header
  },
  mounted() {
    this.showTopMenu=localStorage.getItem('showTopMenu');
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #377dff!important;
}
.border-primary {
  border-color: #377dff!important;
}
</style>