<template>
  <main class="login-main">
    <div class="position-fixed top-0 start-0 end-0 bg-image-login">
      <figure class="position-absolute end-0 bottom-0 start-0 mb-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
          <polygon fill="#fff" points="0,273 1921,273 1921,0 "></polygon>
        </svg>
      </figure>
    </div>
    <div class="container-fluid py-5">
      <div class="row justify-content-center">
        <!-- Col -->
        <div class="col-md-7 col-lg-5">
          <!-- Card -->
          <div class="card">
            <div class="card-body p-4">
              <div class="login-logo text-center mb-3">
                <img alt="logo" src="../../assets/image/logo.png">
              </div>
              <h1 class="text-center login-title">რეგისტრაცია</h1>
              <p class="text-center">
                გაქვს უკვე ექაუნთი?
                <router-link to="/Signin">
                  <a href="Signin">გაიარე ავტორიზაცია</a>
                </router-link>
              </p>
              <br>
              <form class="">

                <!-- Form Group -->
                <div class="form-group">
                  <label class="input-label" for="singupname">სრული სახელი და გვარი</label>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <input class="form-control form-control-lg" type="text" id="singupname" placeholder="სახელი">
                    </div>
                    <div class="col-12 col-md-6">
                      <input class="form-control form-control-lg" type="text" placeholder="გვარი">
                    </div>
                  </div>
                </div>
                <!-- End Form Group -->

                <!-- Form Group -->
                <div class="form-group">
                  <label class="input-label" for="signin-email">ელ-ფოსტა</label>
                  <input class="form-control form-control-lg" type="email" id="signin-email" placeholder="example@davidkenchadze.com">
                </div>
                <!-- End Form Group -->

                <!-- Form Group -->
                <div class="form-group">
                  <label class="input-label" for="singnup-password">პაროლი</label>
                  <input class="form-control form-control-lg" type="password" id="singnup-password" placeholder="პაროლი">
                </div>
                <!-- End Form Group -->

                <!-- Form Group -->
                <div class="form-group">
                  <label class="input-label" for="singnup-repassword">გაიმეორე პაროლი</label>
                  <input class="form-control form-control-lg" type="password" id="singnup-repassword" placeholder="გაიმეორე პაროლი">
                </div>
                <!-- End Form Group -->

                <!-- Form Group -->
                <div class="form-group">
                  <div class="custom-control">
                    <input class="custom-control-input" type="checkbox" id="pass-checkbox">
                    <label class="custom-control-label text-muted" for="pass-checkbox">მე ვეთანხმები პირობებს <a href="#"><b>პირობები</b></a> </label>
                  </div>
                </div>
                <!-- End Form Group -->

                <!-- Button -->
                <button type="submit" class="btn btn-lg btn-block btn-primary">რეგისტრაცია</button>
                <!-- End Button -->

              </form>
            </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Singnup"
}
</script>

<style scoped>

</style>