<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">
          <div class="col-7 mb-4">
            <h1 class="page-title">წესები და ბრძანებები</h1>
            <small class="text-muted">ფაილების რაოდენობა: 5</small>
          </div>
          <!-- Col -->
          <div class="col-5 text-end mb-2">
            <router-link to="/AddRule">
              <a href="AddRule" class="btn btn-custom-dark">დამატება <i class="tio-add"></i></a>
            </router-link>
          </div>
          <!-- End Col -->
          <div class="col-12 mb-2">
            <div class="card card-hover-shadow card-border-rules">
              <div class="card-header d-flex justify-content-between align-items-center">
                <a class="text-dark h5 mb-0" href="Rule">
                  დიქეი კაპიტალის საოპერაციო საქმიანობა (V9)
                </a>
                <div class="d-flex flex-column text-center">
                  <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                  <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->
          <div class="col-12 mb-2">
            <div class="card card-hover-shadow card-border-rules">
              <div class="card-header d-flex justify-content-between align-items-center">
                <a class="text-dark h5 mb-0" href="Rule">
                  დიქეი კაპიტალის მართვის ინსტრუქცია (V3)
                </a>
                <div class="d-flex flex-column text-center">
                  <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                  <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->
          <div class="col-12 mb-2">
            <div class="card card-hover-shadow card-border-rules">
              <div class="card-header d-flex justify-content-between align-items-center">
                <a class="text-dark h5 mb-0" href="Rule">
                  ინფორმაცია ძველი მშენებლობების შესახებ
                </a>
                <div class="d-flex flex-column text-center">
                  <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                  <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->
          <div class="col-12">
            <div class="card card-hover-shadow card-border-rules">
              <div class="card-header d-flex justify-content-between align-items-center">
                <a class="text-dark h5 mb-0" href="Rule">
                  ინფორმაცია ქალაქ თბილისი ფუნქციური ზონების შესახებ
                </a>
                <div class="d-flex flex-column text-center">
                  <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                  <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->
        </div>
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Academy">
              <a href="Academy"><i class="fa fa-arrow-left"></i> უკან</a>
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->
    </main>
    <!-- End Main -->

  <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">ბრძანების წაშლა</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ ვიდეო ბრძანების წაშლა?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-bs-dismiss="modal">გაუქმება</button>
            <button type="button" class="btn btn-custom-danger">თანხმობა</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../../components/Header.vue'
export default {
  name: "Rules",
  components: {
    Header
  }
}
</script>

<style scoped>

</style>