var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('main',{staticClass:"main",class:this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'},[_c('div',{staticClass:"content container-fluid academy"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-12 mt-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex align-items-center text-dark font-weigt-bold"},[_c('h1',{staticClass:"me-3 mb-0"},[_vm._v("03")]),_c('div',[_c('h4',{staticClass:"mb-0"},[_vm._v("შეავსე დილის ინფორმაცია")]),_vm._v(" "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary",attrs:{"title":"კორპორატიული კლიენტი"}},[_vm._v(" თიბისი ბანკი")])])]),_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"row"},[_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5"},[_vm._v("პროდუქტის ტიპი")]),_c('v-select',{attrs:{"placeholder":"აირჩიე პროდუქტის ტიპი","options":[
                      'იყიდება',
                      'ქირავდება',
                      'საინვესტიციო'
                      ]}})],1),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5"},[_vm._v("უძრავი ქონების ტიპი ")]),_c('v-select',{attrs:{"placeholder":"აირჩიე უძრავი ქონების ტიპი","options":[
                      'ბინები',
                      'სასტუმროები',
                      'კომერციული ფართები',
                      'მიწის ნაკვეთები',
                      'სახლები და აგარაკები'
                      ]}})],1),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{staticClass:"h5"},[_vm._v("საძინებლების რაოდენობა ")]),_c('v-select',{attrs:{"multiple":"","placeholder":"აირჩიე საძინებლების რაოდენობა","options":[
                        0,
                        1,
                        2,
                        3,
                        4
                      ]}})],1),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"form-group col-12"},[_c('router-link',{attrs:{"to":"/Finish_Corporate_Deal"}},[_c('button',{staticClass:"btn btn-primary"},[_c('i',{staticClass:"tio-next-ui"}),_vm._v(" შემდეგი")])])],1)])])])])])]),_c('div',{staticClass:"footer",class:this.$store.state.showTopMenu== true ? 'collapsed' : ''},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":"/Corporate_Client_Identification"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" უკან ")])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"page-title"},[_vm._v("კორპორატიული დილის დამატება")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"h5",attrs:{"for":"minAmount"}},[_vm._v("ფასი ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-6"},[_c('label',{attrs:{"for":"minAmount"}},[_vm._v("მინიმალური ($)")]),_c('input',{staticClass:"form-control",attrs:{"id":"minAmount","type":"number","placeholder":"-დან"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-6"},[_c('label',{attrs:{"for":"maxAmount"}},[_vm._v("მაქსიმალური ($)")]),_c('input',{staticClass:"form-control",attrs:{"id":"maxAmount","type":"number","placeholder":"-მდე"}})])
}]

export { render, staticRenderFns }