<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title">კორპორატიული დილის დამატება</h1>
          </div>

          <!-- Col -->
          <div class="col-12 mt-4">
            <form class="card">
              <div class="card-header text-dark font-weigt-bold d-flex align-items-center">
                <h1 class="me-3 mb-0">02</h1>
                <h4 class="mb-0">შეავსე კლიენტის მონაცემები</h4>
              </div>
              <div class="card-body">
                <!-- Row -->
                <div class="row">
                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="name">კლიენტის სახელი</label>
                    <input class="form-control" id="name" type="text" placeholder="კლიენტის სახელი">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="surname">კლიენტის გვარი</label>
                    <input class="form-control" id="surname" type="text" placeholder="კლიენტის გვარი">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="number">ტელეფონის ნომერი</label>
                    <input class="form-control" id="number" type="number" placeholder="ტელეფონის ნომერი">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="number2">ტელეფონის ნომერი (მეორე)</label>
                    <input class="form-control" id="number2" type="number" placeholder="ტელეფონის ნომერი (მეორე)">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="personalId">პირადი ნომერი (ID)</label>
                    <input class="form-control" id="personalId" type="number" placeholder="პირადი ნომერი (ID)">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="email">ელ.ფოსტა</label>
                    <input class="form-control" id="email" type="email" placeholder="ელ.ფოსტა">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5" for="position">თანამდებობა</label>
                    <input class="form-control" id="position" type="email" placeholder="თანამდებობა">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <label class="h5" for="email">ჩანიშვნა</label>
                    <textarea rows="7" class="form-control" placeholder="ჩანიშვნა"></textarea>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <div class="d-flex flex-column flex-md-row align-items-md-center">
                      <router-link class="d-flex flex-column flex-md-row align-items-md-center" to="/Open_Corporate_Deal">
                      <button class="btn btn-primary"><i class="tio-next-ui"></i> შემდეგი</button>
                      </router-link>
                      <div class="mx-0 mx-md-3 my-3 my-md-0 mb-md-0 text-muted text-center">ან</div>
                      <button class="btn btn-white text-primary"><i class="tio-contacts"></i> ახალი საკონტაქტო</button>
                    </div>
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Row -->
              </div>
            </form>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Corporate_Client_Information">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "Corporate_Client_Identification",
  components: {
    Header
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
}
</script>

<style scoped>

</style>