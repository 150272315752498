<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <!-- Deal is't entered -->


      <div v-if="step_2 == null" class="container-fluid my-4">
        <div class="card">
          <div class="card-body">
            <router-link to="/Open_Retail_Deal" v-if="step_1 != null && step_2 == null" class="btn btn-primary">დაასრულე მე-2 ეტაპი</router-link>
            <router-link to="/Retail_Client_Information" v-if="step_1 == null && step_2 == null" class="btn btn-primary">დაიწყე დილის შევსება</router-link>
          </div>
        </div>
      </div>



      <!-- End Deal is't entered -->
      <div v-if="step_2 != null" class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title">რითეილ დილის რედაქტირება</h1>
          </div>

          <!-- Col -->
          <div class="col-12 mt-4">
            <div class="card">
              <div class="card-header d-flex flex-column flex-md-row align-items-md-center text-dark font-weigt-bold overflow-auto">
                <router-link :to="'/Retail_Client_Information_edit/'+this.$route.params.id" class="d-flex align-items-center">
                  <h1 class="me-3 mb-0">01</h1>
                  <h4 class="mb-0 me-3">კლიენტის ინფორმაცია</h4>
                </router-link>
                <router-link :to="'/Open_Retail_Deal_edit/'+this.$route.params.id" class="d-flex align-items-center">
                  <h1 class="me-3 mb-0">02</h1>
                  <h4 class="mb-0 me-3">დილის ინფორმაცია</h4>
                </router-link>
               <div class="d-flex align-items-center">
                <h1 class="me-3 mb-0 text-primary">03</h1>
                <div> <h4 class="mb-0 text-primary">დაასრულე დილის გახსნა</h4> <span v-b-tooltip.hover title="რითეილ კლიენტი"> {{ step_1.name+" "+step_1.surname }}</span></div>
               </div>
              </div>
              <div class="card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <!-- Row -->
                  <form v-on:submit.prevent="handleSubmit(submitForm)" @keydown="clearError" class="row">
                    <!-- Col -->
                    <div class="form-group col-12 col-md-6">
                      <ValidationProvider name="real_estate_status" rules="required" v-slot="{ errors }">
                        <label class="h5">უძრავი ქონების სტატუსი </label>
                        <v-select :class="errors[0] ? 'error' : ''" v-model="form.real_estate_status" placeholder="აირჩიე უძრავი ქონების სტატუსი" label="name" :options="real_estate_status"></v-select>
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
              
                    <!-- Col -->
                    <div class="form-group col-12 col-md-6">
                      <ValidationProvider name="condition" rules="required" v-slot="{ errors }">
                        <label class="h5">უძრავი ქონების კონდიცია </label>
                        <v-select :class="errors[0] ? 'error' : ''" v-model="form.condition" multiple placeholder="აირჩიე უძრავი ქონების კონდიცია" label="name" :options="condition"></v-select>
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
              
                    <!-- Col -->
                    <div class="col-12">
                      <label for="minAmount" class="h5">ფართის მოცულობა </label>
                    </div>
                    <div class="form-group col-6">
                      <ValidationProvider name="minimum_area" :rules="{required: true, integer}" v-slot="{ errors }">
                        <label for="minimum_area">მინიმალური</label>
                        <input :class="errors[0] ? 'error' : ''" v-model="form.minimum_area" id="minimum_area" type="text" class="form-control" placeholder="-დან">
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-6">
                      <ValidationProvider name="max_area" :rules="{required: true, integer}" v-slot="{ errors }">
                        <label for="max_area">მაქსიმალური</label>
                        <input :class="errors[0] ? 'error' : ''" v-model="form.maximum_area" id="max_area" type="text" class="form-control" placeholder="-მდე">
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
              
                    <!-- Col -->
                    <div class="col-12">
                      <label for="minFloorAmount" class="h5">სართულები </label>
                    </div>
                    <div class="form-group col-6">
                      <ValidationProvider name="minimum_floor" :rules="{required: true, integer}" v-slot="{ errors }">
                        <label for="minimum_floor">მინიმალური</label>
                        <input :class="errors[0] ? 'error' : ''" v-model="form.minimum_floor" id="minimum_floor" type="text" class="form-control" placeholder="-დან">
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-6">
                      <ValidationProvider name="maximum_floor" :rules="{required: true, integer}" v-slot="{ errors }">
                        <label for="maximum_floor">მაქსიმალური</label>
                        <input :class="errors[0] ? 'error' : ''" v-model="form.maximum_floor" id="maximum_floor" type="text" class="form-control" placeholder="-მდე">
                        <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <!-- End Col -->
              
                    <!-- Col -->
                    <div class="form-group col-12">
                      <label class="h5">ლოკაცია </label>
                      <hr class="my-0 mb-3">
                      <!-- <GoogleMap :latitude="xCordinate" :longitude="yCordinate" :title="'Google Map'" /> -->
                      <div class="row">
                        <!-- Col Region -->
                        <div class="col-4" v-if="form.location.length == []">
                          <ValidationProvider name="region_address" rules="required" v-slot="{ errors }">
                            <label class="h5">აირჩიე რეგიონი</label>
                            <v-select :class="errors[0] ? 'error' : ''" @input="getRealEstateDistricts()" label="name" v-model="region_address" 
                              placeholder="აირჩიე რეგიონი" :options="realestate_Region"></v-select>
                            <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                         <!-- End Col Region -->

                         <!-- Col Region -->
                        <div class="col-4" v-if="form.location.length != []">
                            <label class="h5">აირჩიე რეგიონი</label>
                            <v-select label="name" @input="getRealEstateDistricts()" v-model="region_address" 
                              placeholder="აირჩიე რეგიონი" :options="realestate_Region"></v-select>
                        </div>
                         <!-- End Col Region -->

                         <!-- Col District -->
                        <div class="col-3">
                            <label class="h5">აირჩიე რაიონი </label>
                            <v-select v-model="district_address" @input="getRealEstateAddress()"
                              placeholder="აირჩიე რეგიონი" label="name" :options="realestate_Districts"></v-select>
                        </div>
                        <!-- End Col District -->

                        <!-- Col Address -->
                        <div class="col-3">
                            <label class="h5">აირჩიე ქუჩა </label>
                            <v-select v-model="street_address" 
                              placeholder="აირჩიე რეგიონი" label="name" :options="realestate_Address"></v-select>
                        </div>
                         <!-- End Col Address -->


                        <!-- Col Address -->
                        <div class="col-2 text-end">
                            <button type="button" class="btn btn-primary w-100" style="padding: 0.903rem 1.09375rem!important;margin-top: 27px;" @click="locationPush()">Add</button>
                        </div>
                         <!-- End Col Address -->

                        <div class="col-12 mt-3">
                          <table class="table table-hover">
                            <tbody v-if="form.location">
                              <tr v-for="(item, index) in form.location" :key="index">
                                <td>{{ item.region_address.name }}</td>
                                <td v-if="item.district_address != null">{{ item.district_address.name }}</td>
                                <td v-if="item.district_address == null"> </td>
                                <td v-if="item.street_address != null"> {{ item.street_address.name }}</td>
                                <td v-if="item.street_address == null"> </td>
                                <td><button @click="deleteLocation(index)" class="btn btn-danger">წაშლა</button></td>
                              </tr>
                            </tbody>
                          </table>
                         </div>

                      </div>
                    </div>
                    <!-- End Col -->
              
                    <!-- Col -->
                    <div class="form-group col-12">
                      <button type="submit" :disabled="form.location.length == []" class="btn btn-primary"><i class="tio-edit"></i> 
                        დილის რედაქტირება
                      </button>
                    </div>
                    <!-- End Col -->
                  </form>
                  <!-- End Row -->
                </ValidationObserver>
              </div>
            </div>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div v-if="step_2 != null" class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Open_Retail_Deal">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->

  </div>
</template>

<script>
import axios from 'axios';
 /* From Validation Massage */
 import { extend } from 'vee-validate/dist/vee-validate.full';
 import { required, integer } from 'vee-validate/dist/rules';
// import GoogleMap from "@/components/GoogleMap.vue";
import Header from '@/components/Header.vue'

export default {
  name: "Finish_Retail_Deal",
  data() {
    return {
      integer: '',
      errors: {},
      form: {
        real_estate_status: '',
        condition: [],
        minimum_area: '',
        maximum_area: '',
        minimum_floor: '',
        maximum_floor: '',
        location: []
      },
      region_address: '',
      district_address: '',
      street_address: '',
      // dealFinish: true,
      step_1: [],
      step_2: [],
      step_3: [],
      // Get Real Estate Status
      real_estate_status: [],
      // Get Real Estate Condition
      condition: [],
      // Get Real Estate Region
      realestate_Region: [],
      // Get Real Estate Districts
      realestate_Districts: [],
      // Get Real Estate Address
      realestate_Address: []


      // xCordinate: 41.69267091117391,
      // yCordinate: 44.836804984197784,
    }
  },
  components: {
    Header,
    // GoogleMap
  },
  watch: {
    district_address(newValue, oldValue) {
      if(newValue == null || oldValue == null) {
         this.district_address = '';
         this.street_address = '';
      }
      this.realestate_Address = [];
      // console.log(`message changed from ${">>>>>>>>" + oldValue.name + "<<<<<<<ძველი"} >>>>>>>>>>> ${newValue.name + "<<<<<<<< ახალი"}}`)
    },
    street_address(newValue, oldValue) {
      if(newValue == null || oldValue == null) {
        this.street_address = '';
      }
      // this.realestate_Districts = [];
      // this.realestate_Address = [];
      // console.log(`message changed from ${">>>>>>>>" + oldValue.name + "<<<<<<<ძველი"} >>>>>>>>>>> ${newValue.name + "<<<<<<<< ახალი"}}`)
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    locationPush() {
      if (this.region_address) {
        this.form.location.push({
          region_address: this.region_address,
          district_address: this.district_address,
          street_address: this.street_address
        });
        // this.dealFinish = false;
      }
      this.region_address = '';
      this.district_address = '';
      this.street_address = '';

      // this.realestate_Region = [];
      this.realestate_Districts = [];
      this.realestate_Address = [];
    },
    submitForm(){

      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.put(process.env.VUE_APP_BACKEND_URL+'/auth/edit/deal/'+this.$route.params.id, {
        step_1: this.step_1,
        step_2: this.step_2,
        step_3: this.form

      }, config )
          .then((res) => {
            // localStorage.removeItem('Step_1');
            // localStorage.removeItem('Step_2');
            // localStorage.removeItem('Step_3');
        
          //  setTimeout(() => {
          //   this.$router.push({
          //     path: '/Deals_Overview'
          //   }, 2000)
          //  })
          })
          .catch((error) => {
            localStorage.setItem('Step_3',JSON.stringify(this.form));
            var error_response=error.response.data;
            if(error.response.status==422){
              this.setErrors(error.response.data.errors);
            }else{
              this.setErrors(error.response.data.message);
            }
            console.log(error);
          });

    },
    getStep_3() {
      const step_3 = JSON.parse(localStorage.getItem("Step_3"));
      if(step_3) {
        this.form.real_estate_status = step_3.real_estate_status;
        this.form.condition = step_3.condition;
        this.form.minimum_area = step_3.minimum_area;
        this.form.max_area = step_3.max_area;
        this.form.minimum_floor = step_3.minimum_floor;
        this.form.maximum_floor = step_3.maximum_floor;
        this.form.location = step_3.location;
      }
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){

      return (fieldName in this.errors);
    },
    clearError(event){

      delete this.errors[event.target.name]
    },
    message() {
      // All Required Field.
      extend('required', {
        ...required,
        message: 'აუცილებელი ველი'
      });
      extend('integer', {
        ...integer,
        message: 'შეიყვანეთ მხოლოდ ციფრები'
      });
    },
     // Real Estate Status
     getRealEstateStatus() {
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          const step_2 = JSON.parse(localStorage.getItem("Step_2"));
          axios.get(process.env.VUE_APP_BACKEND_URL+"/auth/realestate/status/"+step_2.real_estate_type.id, config)
          .then(response => {
            this.real_estate_status = response.data;
          })
          .catch(error => {
            console.log(error);
          });
     

    },
     // Real Estate Condition
     getRealEstateCondition() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL+"/auth/realestate/conditions", config)
      .then(response => {
        this.condition = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
     // Real Estate Region
     getRealEstateRegion() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL+"/auth/realestate/regions", config)
      .then(response => {
        this.realestate_Region = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
     // Real Estate Districts
     getRealEstateDistricts() {
      if (this.region_address) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        axios.get(process.env.VUE_APP_BACKEND_URL + "/auth/realestate/districts/" + this.region_address.id, config)
          .then(response => {
            this.realestate_Districts = response.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
     // Real Estate Address
     getRealEstateAddress() {
      if (this.district_address) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        axios.get(process.env.VUE_APP_BACKEND_URL + "/auth/realestate/streets/" + this.region_address.id + "/" + this.district_address.id, config)
          .then(response => {
            this.realestate_Address = response.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
      },
      deleteLocation(index) {
        this.form.location.splice(index, 1)
      },
      dealData() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/get/deal/info/'+this.$route.params.id, config)
        .then((res) => {
          this.form = res.data.step3
    
          this.region_address = this.form.location.city
          this.district_address = this.form.location.district
          this.street_address = this.form.location.street
        })
        .catch((error) => {
          var error_response = error.response.data;
          if (error.response.status == 422) {
            this.setErrors(error.response.data.errors);
          } else {
            this.setErrors(error.response.data.message);
          }
        });
    }
  },
  mounted() {
    const step_1 = JSON.parse(localStorage.getItem("Step_1"));
    this.step_1 = step_1;
    const step_2 = JSON.parse(localStorage.getItem("Step_2"));
    this.step_2 = step_2;
    const step_3 = JSON.parse(localStorage.getItem("Step_3"));
    this.step_3 = step_3;



    this.scrollToTop();
    this.message();
    this.dealData();
  

    // Get Local Storage Data
    this.getStep_3();

    // Get Real Estate Status
    if(step_2 != null) {
      this.getRealEstateStatus();
    }
  
    // Get Real Estate Condition
    this.getRealEstateCondition();
    // Get Real Estate Region
    this.getRealEstateRegion();


  }
}
</script>

<style scoped>
.error {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
}
.error:focus {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}
</style>

