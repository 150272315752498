<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <div class="row align-items-center">
          <div class="col-10 mb-4">
            <h1 class="page-title">ჩატარებული ტესტები</h1>
            <small class="text-muted">სულ ტესტების რაოდენობა: 5</small>
          </div>
          <!-- Col -->
          <div class="col-2 text-end mb-2">
            <a class="text-secondary search" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="tio-search"></i></a>
          </div>
          <!-- End Col -->
          <div class="col-12">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header botb" id="headingOne">
                  <div class="d-block d-md-flex h2">
                    <div class="accordion-button collapsed shadow-none" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style="cursor: pointer">
                      <div>
                        <div class="mb-3 pb-3 border-bottom border-light">
                          <i class="tio-checkmark-circle-outlined text-custom-success me-2" v-b-tooltip.hover title="პასუხ გაცემული"></i>
                          სალომე ჩემია
                          <span class="ms-1 fw-normal" v-b-tooltip.hover title="გაგზავნის თარიღი">2021-01-00 00:00</span>
                        </div>
                        <span class="text-dark h5"> კითხვა:</span><br>
                        <span class="text-dark mb-0 fw-normal"> როდის უნდა მოვითხოვოთ ამონაწერის განახლება იმისათვის, რომ მოხდეს ბინის სწრაფად გადაფორმება?</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center px-3 py-3">
                      <a href="#" class="btn btn-soft-warning me-2" v-b-tooltip.hover title="სტატუსის გარეშე"><i class="tio-remaining-time"></i></a>
                      <a href="#" class="btn btn-soft-success me-2 active" v-b-tooltip.hover title="სწორია"><i class="tio-done"></i></a>
                      <a href="#" class="btn btn-soft-danger" v-b-tooltip.hover title="არასწორია"><i class="tio-clear"></i></a>
                    </div>
                    </div>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="card rounded-0 border-0">
                        <div class="card-body bg-light">
                          <span class="h5">პასუხის თარიღი: <span class="fw-normal">2021-01-00 00:00</span></span>
                          <p class="d-block">ვინაიდან ამონაწერს აქვს 1 თვიანი ვადა ბინის გადაფორმების მომენტისთვის ამონაწერი უნდა იყოს განახლებული მაქსიმუმ 30 დღის პერიოდში. იმ შემთხვევაში თუ არ არის ამონაწერი განახლებული სასურველია განვაახლოთ გადაფორმების წინა დღეს, რომლის ღირებულება შეადგენს 15 ლარს. თუ ონლაინ მოხდება ამონაწერის განახლება მისი ღირებულება შეადგენს 10 ლარს თუ საჭიროა ამონაწერის განახლება იმავე დღეს მაშინ ფასი არის 50 ლარი. გადახდის მომსახურეობის საკომისიო შეადგენს 1 ლარს.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header botb" id="headingTwo">
                  <div class="d-block d-md-flex h2">
                    <div class="accordion-button collapsed shadow-none" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="cursor: pointer">
                      <div>
                        <div class="mb-3 pb-3 border-bottom border-light">
                          <i class="tio-notifications-on-outlined text-custom-warning me-2" v-b-tooltip.hover title="პასუხ გაუცემელი"></i>
                          გიორგი ანანიძე
                          <span class="ms-1 fw-normal" v-b-tooltip.hover title="გაგზავნის თარიღი">2021-01-00 00:00</span>
                        </div>
                        <span class="text-dark h5"> კითხვა:</span><br>
                        <span class="text-dark mb-0 fw-normal"> რა დრო სჭირდება ამონაწერის განახლებას?</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center px-3 py-3">
                      <a href="#" class="btn btn-soft-warning me-2" v-b-tooltip.hover title="სტატუსის გარეშე"><i class="tio-remaining-time"></i></a>
                      <a href="#" class="btn btn-soft-success me-2" v-b-tooltip.hover title="სწორია"><i class="tio-done"></i></a>
                      <a href="#" class="btn btn-soft-danger active" v-b-tooltip.hover title="არასწორია"><i class="tio-clear"></i></a>
                    </div>
                    </div>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="card rounded-0 border-0">
                        <div class="card-body bg-light">
                          <span class="h5">პასუხის თარიღი: <span class="fw-normal">2021-01-00 00:00</span></span>
                          <p class="d-block">.....</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header botb" id="headingThree">
                  <div class="d-block d-md-flex h2">
                    <div class="accordion-button collapsed shadow-none" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style="cursor: pointer">
                      <div>
                        <div class="mb-3 pb-3 border-bottom border-light">
                          <i class="tio-checkmark-circle-outlined text-custom-success me-2" v-b-tooltip.hover title="პასუხ გაცემული"></i>
                          ეკა მოლაშვილი
                          <span class="ms-1 fw-normal" v-b-tooltip.hover title="გაგზავნის თარიღი">2021-01-00 00:00</span>
                        </div>
                        <span class="text-dark h5"> კითხვა:</span><br>
                        <span class="text-dark mb-0 fw-normal"> რა ხდება იმ დროს, როდესაც მყიდველი და გამყიდველი იმყოფებიან სხვადასხვა ადგილას, როგორ ხდება უძრავი ქონების გადაფორმება?</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center px-3 py-3">
                      <a href="#" class="btn btn-soft-warning me-2 active" v-b-tooltip.hover title="სტატუსის გარეშე"><i class="tio-remaining-time"></i></a>
                      <a href="#" class="btn btn-soft-success me-2" v-b-tooltip.hover title="სწორია"><i class="tio-done"></i></a>
                      <a href="#" class="btn btn-soft-danger" v-b-tooltip.hover title="არასწორია"><i class="tio-clear"></i></a>
                    </div>
                    </div>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="card rounded-0 border-0">
                        <div class="card-body bg-light">
                          <span class="h5">პასუხის თარიღი: <span class="fw-normal">2021-01-00 00:00</span></span>
                          <p class="d-block"> თუ მყიდველი არის მაგ: თბილისში, მიდის თბილისის საჯარო რეესტრში აბარებს ნასყიდობის ხელშეკრულებას და თანხას იხდის არსებული ტარიფის შესაბამისად. - მეპატრონე, რომელიც იმყოფებ მაგ: ბათუმში 2 საათის განმავლობაში მიდის ბათუმის საჯარო რეესტრში (იუსტიციის სახლში) და ხელმოწერით ადასტურებს ნასყიდობის ხელშეკრულებას. - ასეთ შემთხვევაში არსებულ ტარიფებს ემატება საბუთზე ხელმოწერის საკომისიო რომელიც შეადგენს 5 ლარს ამ შემთხვევაში შეთანხმების საფუძველზე იხდის ან მყიდველი ან მეპატრონე.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->
        </div>
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Academy">
              <a href="Academy"><i class="fa fa-arrow-left"></i> უკან</a>
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->
    </main>
    <!-- End Main -->

    <!-- Modal -->
    <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
      <div class="offcanvas-header border-bottom">
        <h4 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">ძებნა</h4>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form class="row">
          <label class="h5">თარიღი</label>
          <div class="col-6 form-group">
            <date-picker class="form-control p-0" v-model="time1" valueType="format" id="from" placeholder="-დან"></date-picker>
          </div>
          <div class="col-6 form-group">
            <date-picker class="form-control p-0" v-model="time2" valueType="format" id="to" placeholder="-მდე"></date-picker>
          </div>
          <div class="col-12 form-group">
            <label class="h5">კონსულტანტი</label>
            <Select2 class="form-custom-control" v-model="myValue" :options="myOptions" :settings="{ width: '100%' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="აირჩიე კონსულტანტი"/>
          </div>
          <div class="col-12 form-group">
            <label class="h5">სტატუსი</label>
            <Select2 class="form-custom-control" v-model="myValue1" :options="myOptions1" :settings="{ width: '100%' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="აირჩიე სტატუსი"/>
          </div>
          <div class="col-12 form-group">
            <label class="h5">პასუხი</label>
            <Select2 class="form-custom-control" v-model="myValue2" :options="myOptions2" :settings="{ width: '100%' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="აირჩიე პასუხის სტატუსი"/>
          </div>
        </form>
      </div>
      <div class="offcanvas-footer p-3 border-top bg-white">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-block btn-primary"><i class="fa fa-search me-1"></i> შესრულება</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-block btn-white"><i class="tio-remove-from-trash text-secondary me-1"></i> გასუფთავება</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import Header from '../../../components/Header.vue'
import DatePicker from 'vue2-datepicker'
import Select2 from 'v-select2-component'

export default {
  name: "AcademyTest",
  data(){
    return {
      time1:null,
      time2:null,
      myValue: '',
      myValue1: '',
      myValue2: '',
      myOptions: [
        {id: 1, text: 'გიორგი ანანიძე'},
        {id: 2, text: 'სალომე ჩემია'},
        {id: 3, text: 'თაია არაბული'}
      ],
      myOptions1: [
        {id: 1, text: 'უსტატუსო'},
        {id: 2, text: 'სწორია'},
        {id: 3, text: 'არასწორია'}
      ],
      myOptions2: [
        {id: 1, text: 'პასუხ გაუცემელი'},
        {id: 2, text: 'პასუხ გაცემული'}
      ]
    }
  },
  components: {
    Header,
    DatePicker,
    Select2
  },
  methods: {
    myChangeEvent(val){
      console.log(val);
    },
    mySelectEvent({id, text}){
      console.log({id, text})
    }
  }
}
</script>

<style scoped>
a:hover {
  color: #0052ea!important;
}
.accordion-button {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.4;
}
.accordion-button::after {
  content: "\e904";
  font-family: 'The-Icon-of' !important;
  font-size: 1.125em;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: none;
}
.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  outline: none!important;
}
.accordion-button:not(.collapsed)::after {
  content: "\e954";
  font-family: 'The-Icon-of' !important;
  font-size: 1.125em;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: none;
  transform: rotate(0deg);
}
.tab-content {
  border-radius: 0.75rem;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.75rem - 1px) !important;
  border-top-right-radius: calc(0.75rem - 1px) !important;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.accordion-body {
  padding: 0;
}
h2 {
  margin-bottom: 0!important;
}
.botb {
  border-bottom: 3px solid #ededed;
}
.botb:hover {
  border-bottom: 3px solid #377dff;
}
.accordion-item:last-of-type .botb {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.accordion-item:last-of-type .card {
  border-bottom-left-radius: 0.75rem!important;
  border-bottom-right-radius: 0.75rem!important;
}
.card .card-title {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.4;
}
</style>