<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">
          <!-- Col -->
          <div class="col-7 mb-4">
            <h1 class="page-title">ხშირად დასმული კითხვები</h1>
            <small class="text-muted">კითხვების რაოდენობა: 5</small>
          </div>
          <!-- End Col -->
          <div class="col-5 text-end mb-2">
            <router-link to="/AddFaq">
              <a href="/AddFaq" class="btn btn-custom-success">დამატება <i class="tio-add"></i></a>
            </router-link>
          </div>
          <!-- End Col -->
          <div class="col-12">
            <div class="accordion row" id="accordionVideo">
              <!-- Col -->
              <div class="col-12 mt-2">
                <div class="accordion-item">
                  <div class="accordion-header faq-bot" id="headingVideoOne">
                    <h2 class="d-flex">
                      <div class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVideoOne" aria-expanded="false" aria-controls="collapseVideoOne">
                        როდის უნდა მოვითხოვოთ ამონაწერის განახლება იმისათვის, რომ მოხდეს ბინის სწრაფად გადაფორმება?
                      </div>
                      <div class="d-flex flex-column text-center pt-2 px-3">
                        <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                        <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                      </div>
                    </h2>
                    <div id="collapseVideoOne" class="accordion-collapse collapse" aria-labelledby="headingVideoOne" data-bs-parent="#accordionVideo">
                      <div class="accordion-body">
                        <div class="card rounded-0 border-0 border-top">
                          <div class="card-body">
                            <p>ვინაიდან ამონაწერს აქვს 1 თვიანი ვადა ბინის გადაფორმების მომენტისთვის ამონაწერი უნდა იყოს განახლებული მაქსიმუმ 30 დღის პერიოდში. იმ შემთხვევაში თუ არ არის ამონაწერი განახლებული სასურველია განვაახლოთ გადაფორმების წინა დღეს, რომლის ღირებულება შეადგენს 15 ლარს. თუ ონლაინ მოხდება ამონაწერის განახლება მისი ღირებულება შეადგენს 10 ლარს თუ საჭიროა ამონაწერის განახლება იმავე დღეს მაშინ ფასი არის 50 ლარი. გადახდის მომსახურეობის საკომისიო შეადგენს 1 ლარს.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Col -->
              <div class="col-12 mt-2">
                <div class="accordion-item">
                <div class="accordion-header faq-bot" id="headingVideoTwo">
                  <h2 class="d-flex">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVideoTwo" aria-expanded="false" aria-controls="collapseVideoTwo">
                      რა დრო სჭირდება ამონაწერის განახლებას?
                    </button>
                    <div class="d-flex flex-column text-center pt-2 px-3">
                      <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                      <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                    </div>
                  </h2>
                  <div id="collapseVideoTwo" class="accordion-collapse collapse" aria-labelledby="headingVideoTwo" data-bs-parent="#accordionVideo">
                    <div class="accordion-body">
                      <div class="card rounded-0 border-0 border-top">
                        <div class="card-body">
                          <p>
                            იმ შემთხვევაში თუ ჩვენ ვისარგებლებთ ამონაწერის განახლების სტანდარტული პაკეტით ამონაწერის განახლებას სჭირდება 1 სამუშაო დღე, ხოლო დაჩქარებული წესით არჩევის შემთხვევაში ამონაწერის განახლება მოხდება იმ დღესვე.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <!-- End Col -->
              <div class="col-12 mt-2">
                <div class="accordion-item">
                <div class="accordion-header faq-bot" id="headingVideoThree">
                  <h2 class="d-flex">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVideoThree" aria-expanded="false" aria-controls="collapseVideoThree">
                      რა თანხა არის საჭირო ბინის გადაფორმებისთვის?
                    </button>
                    <div class="d-flex flex-column text-center pt-2 px-3">
                      <a href="#" class="h4 text-dark mb-2"><i class="tio-edit"></i></a>
                      <span class="text-danger h4 mb-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;"><i class="tio-delete"></i></span>
                    </div>
                  </h2>
                  <div id="collapseVideoThree" class="accordion-collapse collapse" aria-labelledby="headingVideoThree" data-bs-parent="#accordionVideo">
                    <div class="accordion-body">
                      <div class="card rounded-0 border-0 border-top">
                        <div class="card-body">
                          <p>
                            ბინის სტანდარტული პაკეტის არჩევის შემთხვევაში გადაფორმების ღირებულება შეადგენს 55 ლარს, დაჩქარებული წესით გადაფორმების შემთხვევაში მისი ღირებულება იზრდება 200 ლარამდე - ორივე შემთხვევაში გადახდის მომსახურეობის საკომისიო შეადგენს 1 ლარს
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <!-- End Col -->
            </div>
          </div>
          <!-- End Col -->
        </div>
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Academy">
              <a href="Academy"><i class="fa fa-arrow-left"></i> უკან</a>
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->
    </main>
    <!-- End Main -->

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">ბრძანების წაშლა</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ ვიდეო ბრძანების წაშლა?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white" data-bs-dismiss="modal">გაუქმება</button>
            <button type="button" class="btn btn-custom-danger">თანხმობა</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import Header from '../../../components/Header.vue'
export default {
  name: "Faq",
  components: {
    Header
  }
}
</script>

<style scoped>
.accordion-button {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.4;
  padding: 1.50rem 1.25rem;
  box-shadow: none!important;
}
.accordion-button::after {
content: "\e904";
font-family: 'The-Icon-of' !important;
font-size: 1.125em;
speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
letter-spacing: 0;
-webkit-font-feature-settings: "liga";
-moz-font-feature-settings: "liga=1";
-moz-font-feature-settings: "liga";
-ms-font-feature-settings: "liga" 1;
font-feature-settings: "liga";
-webkit-font-variant-ligatures: discretionary-ligatures;
font-variant-ligatures: discretionary-ligatures;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
background-image: none;
}
.accordion-button:not(.collapsed) {
background-color: #ffffff;
outline: none!important;
}
.accordion-button:not(.collapsed)::after {
content: "\e954";
font-family: 'The-Icon-of' !important;
font-size: 1.125em;
speak: none;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
letter-spacing: 0;
-webkit-font-feature-settings: "liga";
-moz-font-feature-settings: "liga=1";
-moz-font-feature-settings: "liga";
-ms-font-feature-settings: "liga" 1;
font-feature-settings: "liga";
-webkit-font-variant-ligatures: discretionary-ligatures;
font-variant-ligatures: discretionary-ligatures;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
background-image: none;
transform: rotate(0deg);
}
.accordion-item:first-of-type {
border-top-left-radius: 0.75rem;
border-top-right-radius: 0.75rem;
}
.accordion-item:first-of-type .accordion-button {
border-top-left-radius: calc(0.75rem - 1px) !important;
border-top-right-radius: calc(0.75rem - 1px) !important;
}
.accordion-item:last-of-type {
border-bottom-left-radius: 0.75rem;
border-bottom-right-radius: 0.75rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
border-bottom-left-radius: 0.75rem;
border-bottom-right-radius: 0.75rem;
}
.accordion-body {
padding: 0px;
}
h2 {
margin-bottom: 0!important;
}
.faq-bot {
border-bottom: 3px solid #ededed;
}
.faq-bot:hover {
border-bottom: 3px solid #00c9a7!important;
}
.accordion-item:last-of-type .faq-bot {
border-bottom-left-radius: 0.75rem;
border-bottom-right-radius: 0.75rem;
}
.accordion-item:last-of-type .card {
border-bottom-left-radius: 0.75rem!important;
border-bottom-right-radius: 0.75rem!important;
}
</style>