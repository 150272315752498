<template>
    <div>
      <Header/>
      <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
        <div class="content container-fluid academy">
          <!-- Row -->
          <div class="row align-items-center">
  
  
            <div class="col-12">
              <h1 class="page-title">რითეილ კლიენტის რედაქტირება</h1>
            </div>
  
            <!-- Col -->
            <div class="col-12 mt-4">
              <div class="card">
                <div class="card-header text-dark font-weigt-bold d-flex flex-column flex-md-row align-items-md-center overflow-auto">
                  <div class="d-flex align-items-center">
                    <h1 class="me-3 mb-0 text-primary">01</h1>
                    <h4 class="mb-0 me-3 text-primary">კლიენტის ინფორმაცია</h4>
                  </div>
                </div>
                <div class="card-body">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <!-- Row -->
                    <form v-on:submit.prevent="handleSubmit(submitForm)" @keydown="clearError" class="row">
                      <!-- Col Client Name -->
                      <div class="form-group col-12 col-md-6">
                        <ValidationProvider name="name"  rules="required" v-slot="{ errors }">
                          <label class="h5" for="name">კლიენტის სახელი</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.name" class="form-control" id="name" type="text" placeholder="კლიენტის სახელი">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Col Client Name -->
                  
                      <!-- Col Client LastName -->
                      <div class="form-group col-12 col-md-6">
                        <ValidationProvider name="surname" rules="required" v-slot="{ errors }">
                          <label class="h5" for="surname">კლიენტის გვარი</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.surname" class="form-control" id="surname" type="text" placeholder="კლიენტის გვარი">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Col Client LastName -->
  
                      <!-- Col Type of Citizen -->
                      <div class="form-group col-12" :class="form.country_status == '' ? 'col-md-6' : ''">
                        <ValidationProvider name="country_status" rules="required" v-slot="{ errors }">
                          <label class="h5">მოქალაქე </label>
                          <v-select :class="errors[0] ? 'error' : ''" v-model="form.country_status" label="name" placeholder="აირჩიე სტატუსი" :options="[
                            {id: 1, name: 'საქართველოს მოქალაქე'},
                            {id: 2, name: 'უცხოელი'}
                        ]">
                          </v-select>
  
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Col Type of Citizen -->
  
                    
                      <!-- Mobile Col For Georgians -->
                      <div class="form-group col-12 col-md-6" v-if="form.country_status == 1 && form.country_status != ''">
                        <ValidationProvider name="ge_mobile"  rules="required|min:9|max:9|integer" v-slot="{ errors }">
                          <label class="h5" for="ge_mobile">ტელეფონის ნომერი</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.ge_mobile" class="form-control" id="ge_mobile" type="text" placeholder="ტელეფონის ნომერი">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Mobile Col For Georgians -->
                  
                      <!-- Second Mobile Col For Georgians -->
                      <div class="form-group col-12 col-md-6" v-if="form.country_status.id == 1 && form.country_status != ''">
                        <ValidationProvider name="ge_second_mobile" rules="min:9|max:9|integer" v-slot="{ errors }">
                          <label class="h5" for="ge_second_mobile">ტელეფონის ნომერი (მეორე)</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.ge_second_mobile" class="form-control" id="ge_second_mobile" type="text"
                            placeholder="ტელეფონის ნომერი (მეორე)">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- Second Mobile Col For Georgians -->
                  
                      <!-- Personal ID Col For Georgians -->
                      <div class="form-group col-12 col-md-6" v-if="form.country_status.id == 1">
                        <ValidationProvider name="personal_id" rules="required|length:11|integer" v-slot="{ errors }">
                          <label class="h5" for="personal_id">პირადი ნომერი (ID)</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.personal_id" class="form-control" id="personal_id" type="text" placeholder="პირადი ნომერი (ID)">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Personal ID Col For Georgians -->
  
  
                      <!-- Mobile Col For Foreigner -->
                       <div class="form-group col-12 col-md-6" v-if="form.country_status.id == 2">
                        <ValidationProvider name="foreigner_mobile" rules="required|integer" v-slot="{ errors }">
                          <label class="h5" for="foreigner_mobile">ტელეფონის ნომერი</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.foreigner_mobile" class="form-control" id="foreigner_mobile" type="text" placeholder="ტელეფონის ნომერი">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Mobile Col For Foreigner -->
                  
                      <!-- Second Mobile Col For Foreigner -->
                      <div class="form-group col-12 col-md-6" v-if="form.country_status.id == 2">
                        <ValidationProvider name="foreigner_personal_id" rules="integer" v-slot="{ errors }">
                          <label class="h5" for="foreigner_second_mobile">ტელეფონის ნომერი (მეორე)</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.foreigner_second_mobile" class="form-control" id="foreigner_second_mobile" type="text" placeholder="ტელეფონის ნომერი (მეორე)">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Second Mobile Col For Foreigner -->
                  
                      <!-- Persona ID Col For Foreigner -->
                      <div class="form-group col-12 col-md-6" v-if="form.country_status.id == 2">
                        <ValidationProvider name="foreigner_personal_id" rules="required|integer" v-slot="{ errors }">
                          <label class="h5" for="foreigner_personal_id">პირადი ნომერი (ID)</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.foreigner_personal_id" class="form-control" id="foreigner_personal_id" type="text" placeholder="პირადი ნომერი (ID)">
                          <span class="text-danger" v-if="errors[0]">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Persona ID Col For Foreigner -->
  
                  
                      <!-- Col Email -->
                      <div class="form-group col-12 col-md-6">
                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                          <label class="h5" for="email">ელ.ფოსტა</label>
                          <input :class="errors[0] ? 'error' : ''" v-model="form.email" class="form-control" id="email" type="email" placeholder="ელ.ფოსტა">
                          <span class="text-danger" v-if="errors[0]">{{errors[0]}}</span>
                        </ValidationProvider>
                      </div>
                      <!-- End Col Email -->
                  
                      <!-- Col Description -->
                      <div class="form-group col-12">
                        <label class="h5" for="description">ჩანიშვნა</label>
                        <textarea v-model="form.description" id="description" rows="7" class="form-control" placeholder="ჩანიშვნა"></textarea>
                      </div>
                      <!-- End Col Description -->
                  
                      <!-- Col Submit -->
                      <div class="form-group col-12">
                        <button class="btn btn-primary"><i class="tio-edit"></i> რედაქტირება</button>
                      </div>
                      <!-- End Col Submit -->
                    </form>
                    <!-- End Row -->
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <!-- End Col -->
  
          </div>
          <!-- End Row -->
        </div>
        <!-- End Content -->
  
        <!-- Footer -->
        <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
          <div class="row">
            <div class="col">
              <router-link to="/Deals_Overview">
                <i class="fa fa-arrow-left"></i> უკან
              </router-link>
            </div>
          </div>
        </div>
        <!-- End Footer -->
  
      </main>
      <!-- End Main -->
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue'
   /* From Validation Massage */
   import { extend } from 'vee-validate/dist/vee-validate.full';
   import { required, email, length, max , min, integer, regex} from 'vee-validate/dist/rules';
   import axios from 'axios';
  
  export default {
    name: "edit",
    data() {
      return {
        errors: {},
        required: '',
        form: {
          name: '',
          surname: '',
  
          country_status: '',
  
          ge_mobile: '',
          ge_second_mobile: '',
          personal_id: '',
  
          foreigner_mobile: '',
          foreigner_second_mobile: '',
          foreigner_personal_id: '',
  
          email: '',
          description: ''
        },
      
      }
    },
    components: {
      Header
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0,0);
      },
      submitForm(){

        if (this.form.country_status.id == 1) {
            this.form.foreigner_mobile = '';
            this.form.foreigner_second_mobile = '';
            this.form.foreigner_personal_id = '';
        }
        if (this.form.country_status.id == 2) {
            this.form.ge_mobile = '';
            this.form.ge_second_mobile = '';
            this.form.personal_id = '';
        }
                      
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        axios.put(process.env.VUE_APP_BACKEND_URL+'/auth/edit/client/'+this.$route.params.id, this.form, config)
            .then((res) => {
                      this.$router.push({
                          path: `/Retail_Client_Details/${this.$route.params.id}`
                      });
            })
            .catch((error) => {
              var error_response=error.response.data;
              if(error.response.status==422){
                this.setErrors(error.response.data.errors);
              }else{
                this.setErrors(error.response.data.message);
              }
            })
        
      },
      onFailure(message){
        this.error=true;
      },
      setErrors(errors){
        this.errors=errors;
      },
      hasError(fieldName){
  
        return (fieldName in this.errors);
      },
      clearError(event){
  
        delete this.errors[event.target.name]
      },
      message() {
        // For Valid Email.
        extend('email', {
          ...email,
          message: 'გთხოვთ შეიყვანოთ სწორი ელ.ფოსტა'
        }
        );
        // All Required Field.
        extend('required', {
          ...required,
          message: 'აუცილებელი ველი'
        });
        // Geo Personal ID.
        extend('length', {
          ...length,
          message: 'არ უნდა შეიცავდეს 11 სიმბოლოზე მეტს ან ნაკლებს'
        });
        // Geo Mobile Numbers.
        extend('integer', {
          ...integer,
          message: 'შეიყვანეთ მხოლოდ ციფრები'
        });
        extend('min', {
          ...min,
          message: 'არ უნდა შეიცავდეს 9 სიმბოლოზე ნაკლებს'
        });
        extend('max', {
          ...max,
          message: 'არ უნდა შეიცავდეს 9 სიმბოლოზე მეტს'
        });
        extend('regex', {
          ...regex,
          message: 'ფორმატი არასწორია'
        });
      },
      clientData() {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        const check = JSON.parse(localStorage.getItem('Step_1'))
        // if(check && this.$route.params.id)
        axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/client/get/'+this.$route.params.id, config)
          .then((res) => {
            this.form = res.data;
            if(this.form.country_status == '1' ) {
                this.form.country_status = {
                    id: 1,
                    name: "საქართველოს მოქალაქე"
                }
            } else {
                this.form.country_status = {
                    id: 2,
                    name: "უცხოელი"
                }
            }
  
        
          })
          .catch((error) => {
            var error_response = error.response.data;
            if (error.response.status == 422) {
              this.setErrors(error.response.data.errors);
            } else {
              this.setErrors(error.response.data.message);
            }
          });
      },
    },
    mounted() {
      this.clientData();
      
      this.scrollToTop();
      this.message();
     
      
      
    }
  }
  </script>
  
  <style scoped>
  .error {
      animation: shake 0.2s ease-in-out 0s 2;
      box-shadow: 0 0 0.5em red;
  }
  .error:focus {
      animation: shake 0.2s ease-in-out 0s 2;
      box-shadow: 0 0 0.5em red;
  }
  
  @keyframes shake {
    0% { margin-left: 0rem; }
    25% { margin-left: 0.5rem; }
    75% { margin-left: -0.5rem; }
    100% { margin-left: 0rem; }
  }
  </style>