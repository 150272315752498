<template>
    <div>
        <Header />
        <main class="main" :class="this.$store.state.showTopMenu == true ? 'collapsed ' : 'uncollapsed'">
            <div class="content container-fluid academy">
                <div class="row align-items-center justify-content-between">


                    <div class="col-auto">
                        <h1 class="page-title">რეგიონი</h1>
                    </div>

                    <div class="col-auto" data-bs-toggle="offcanvas" data-bs-target="#offcanvasClosingDeals"
                        aria-controls="offcanvasRight">
                        <a href="/AddRegion">
                            <div class="rounded card-body  text-white border-custom-success bg-green card-hover ">
                                <div class="row align-items-center gx-2">
                                    <div class="col">
                                        <i class=" tio-add font-weight-normal display-8"></i>
                                    </div>
                                    <div class="col-auto">
                                        <span class="h-6 text-white">
                                            რეგიონის დამატება
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </a>
                    </div>

                </div>
            </div>
            <div class="content container-fluid academy">
                <div class="bg-body  shadow-sm bg-light fullrounded  ">
                    <div
                        class="bg-light p-3 border border-black roundedtop d-flex justify-content-between align-items-center ">
                        <span class="h2">რეგიონების სია</span>
                        <div class="input-group form-outline w-50">
                            <input type="text" class="col-6 border border-black rounded p-2 form-control "
                                placeholder="ძებნა...">
                            <i class="tio-search"></i>
                        </div>
                    </div>
                    <div class="p-3 ">

                        <div class="d-flex justify-content-between align-items-center text-muted py-3 border-bottom">
                            <p class="pb-0 mb-0 small lh-sm h2">თბილისი</p>

                            <div class="d-flex align-items-center gap-4">
                                <div class="col-auto">
                                    <a href="EditRegion">
                                        <div class="rounded  bg-blue text-white p-2 hover-overlay">
                                            <div class="row flex-row-reverse align-items-center gx-2">
                                                <div class="col">
                                                    <i class=" tio-open-in-new font-weight-normal display-8"></i>
                                                </div>
                                                <div class="col-auto">
                                                    <span class="h-6 text-white btn-sm">
                                                        რედაქტირება
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                    </a>
                                </div>

                                <div class="col-auto">
                                    <router-link to="#">
                                        <a href="#">
                                            <div class="rounded  bg-red text-white p-2" data-bs-toggle="modal"
                                                data-bs-target="#DeleteRegion">
                                                <div class="row flex-row-reverse align-items-center gx-2">
                                                    <div class="col">
                                                        <i class=" tio-delete font-weight-normal display-8"></i>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span class="h-6 text-white btn-sm">
                                                            წაშლა
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>

                                        </a>

                                    </router-link>
                                </div>
                            </div>

                        </div>


                        <div class="d-flex justify-content-between align-items-center text-muted py-3 border-bottom">
                            <p class="pb-0 mb-0 small lh-sm h2">რუსთავი</p>

                            <div class="d-flex align-items-center gap-4">
                                <div class="col-auto">
                                    <a href="EditRegion">
                                        <div class="rounded  bg-blue text-white p-2 hover-overlay ">
                                            <div class="row flex-row-reverse align-items-center gx-2">
                                                <div class="col">
                                                    <i class=" tio-open-in-new font-weight-normal display-8"></i>
                                                </div>
                                                <div class="col-auto">
                                                    <span class="h-6 text-white btn-sm">
                                                        რედაქტირება
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                    </a>
                                </div>

                                <div class="col-auto">
                                    <router-link to="#">
                                        <a href="#">
                                            <div class="rounded  bg-red text-white p-2" data-bs-toggle="modal"
                                                data-bs-target="#DeleteRegion">
                                                <div class="row flex-row-reverse align-items-center gx-2">
                                                    <div class="col">
                                                        <i class=" tio-delete font-weight-normal display-8"></i>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span class="h-6 text-white btn-sm">
                                                            წაშლა
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>

                                        </a>

                                    </router-link>
                                </div>
                            </div>

                        </div>


                        <div class="d-flex justify-content-between align-items-center text-muted py-3 border-bottom">
                            <p class="pb-0 mb-0 small lh-sm h2">ბათუმი</p>

                            <div class="d-flex align-items-center gap-4">
                                <div class="col-auto">
                                    <a href="EditRegion">
                                        <div class="rounded  bg-blue text-white p-2 hover-overlay">
                                            <div class="row flex-row-reverse align-items-center gx-2">
                                                <div class="col">
                                                    <i class=" tio-open-in-new font-weight-normal display-8"></i>
                                                </div>
                                                <div class="col-auto">
                                                    <span class="h-6 text-white btn-sm">
                                                        რედაქტირება
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                    </a>
                                </div>

                                <div class="col-auto">
                                    <router-link to="#">
                                        <a href="#">
                                            <div class="rounded  bg-red text-white p-2" data-bs-toggle="modal"
                                                data-bs-target="#DeleteRegion">
                                                <div class="row flex-row-reverse align-items-center gx-2">
                                                    <div class="col">
                                                        <i class=" tio-delete font-weight-normal display-8"></i>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span class="h-6 text-white btn-sm">
                                                            წაშლა
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>

                                        </a>

                                    </router-link>
                                </div>
                            </div>

                        </div>


                        <div class="d-flex justify-content-between align-items-center text-muted py-3 border-bottom">
                            <p class="pb-0 mb-0 small lh-sm h2">თელავი</p>

                            <div class="d-flex align-items-center gap-4">
                                <div class="col-auto">
                                    <a href="EditRegion">
                                        <div class="rounded  bg-blue text-white p-2 hover-overlay">
                                            <div class="row flex-row-reverse align-items-center gx-2">
                                                <div class="col">
                                                    <i class=" tio-open-in-new font-weight-normal display-8"></i>
                                                </div>
                                                <div class="col-auto">
                                                    <span class="h-6 text-white btn-sm">
                                                        რედაქტირება
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                    </a>
                                </div>

                                <div class="col-auto">
                                    <router-link to="#">
                                        <a href="#">
                                            <div class="rounded  bg-red text-white p-2" data-bs-toggle="modal"
                                                data-bs-target="#DeleteRegion">
                                                <div class="row flex-row-reverse align-items-center gx-2">
                                                    <div class="col">
                                                        <i class=" tio-delete font-weight-normal display-8"></i>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span class="h-6 text-white btn-sm">
                                                            წაშლა
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>

                                        </a>

                                    </router-link>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>


        </main>

        <div class="modal fade" id="DeleteRegion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">წაშლა</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ნამდვილად გსურთ ამ ქმედების განხორციელება?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                        <button type="button" class="btn btn-custom-danger"><i class="tio-delete"></i> თანხმობა</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
    name: "Region",
    components: {
        Header
    }
}

</script>

<style scoped>
.card-body {
    padding: 0.75rem;
}

.h2 {
    margin-top: 0.5em;
    min-width: 120px
}

.input-group {
    align-items: center;
    flex-direction: row-reverse;
}

.tio-search {
    position: absolute;
    right: 12px;
    cursor: pointer;

}

.bg-blue {
    background-color: #377dff;
    border-color: #377dff;
}

.hover-overlay:hover {
    background-color: #1164ff;
    border-color: #045cff;
}

.bg-green {

    background-color: #00c9a7;
    border-color: #00c9a7;
}

.card-hover:hover {
    background-color: #00a387;
    border-color: #00967d;
}

.roundedtop {
    border-radius: 0.75rem 0.75rem 0 0;
}

.bg-red {
    background-color: #ed4c78;
    border-color: #ed4c78;
}

.bg-red:hover {
    background-color: #ea295e;
    border-color: #e81e55;
}

.fullrounded {
    border-radius: 0.75rem;
}
</style>
