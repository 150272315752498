<template>
    <div>
         <Header />
         <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
             <div class="content container-fluid">
                 <div class="page-header">
                     <div class="row align-items-end">
                         <div class="col-sm mb-2 mb-sm-0">
                            <h1 class="page-header-title">როლის რედაქტირება</h1>
                             <nav aria-label="breadcrumb">
                                 <ol class="breadcrumb breadcrumb-no-gutter">
                                    <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/Roles">როლების სია</router-link></li>
                                    <li class="breadcrumb-item active" aria-current="page">როლის რედაქტირება</li>
                                 </ol>
                             </nav>
                         </div>
                     </div>
                     <!-- End Row -->
                 </div>
 
 
       
                 <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <!-- Col -->
                            <div class="col-12">
                                <!-- Card -->
                                <div class="col-12 form-group">
                                    <label for="role" class="control-label">დასახელება</label>
                                        <input class="form-control" placeholder="როლის დასახელება" name="role" type="text" id="role">
                                </div>

                                <div class="col-12 form-group">
                                    <label for="permission" class="control-label">მონიშნე ფერმიშენები</label>
                                    <v-select placeholder="აირჩიე ფერმიშენი" multiple :options="[
                                        'users_manage',
                                        'agrement_manage',
                                        'deal_manage',
                                        'clients_manage',
                                        'developer_manage'
                                        ]">
                                    </v-select>
                                    <p class="help-block"></p>
                                    <input class="btn btn-danger" type="submit" value="შენახვა">
                                </div>
                                <!-- End Card -->
                            </div>
                            <!-- End Col -->
                        </div>
                    </div>
                 </div>
             </div>
             <!-- End Content -->
 
         
             <!-- Footer -->
             <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
             <div class="row">
                 <div class="col">
                 <router-link to="/Roles">
                     <i class="fa fa-arrow-left"></i> უკან
                 </router-link>
                 </div>
             </div>
             </div>
             <!-- End Footer -->
         
         </main>
     </div> 
 </template>
 
 <script>
   import Header from '@/components/Header.vue'
 
 export default {
     name: "Edit",
     components: {
         Header
     }
 }
 </script>
 
 <style>
 
 </style>>