<template>
  <main class="login-main">
    <div class="position-fixed top-0 start-0 end-0 bg-image-login">
      <figure class="position-absolute end-0 bottom-0 start-0 mb-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
          <polygon fill="#fff" points="0,273 1921,273 1921,0 "></polygon>
        </svg>
      </figure>
    </div>
    <div class="container-fluid py-5">
      <div class="row justify-content-center">
        <!-- Col -->
        <div class="col-md-7 col-lg-5">
          <!-- Card -->
          <div class="card">
            <div class="card-body p-4">
              <div class="text-center mb-5">
                <h1 class="text-center login-title">დაგავიწყდა პაროლი?</h1>
                <p>შეიყვანეთ ელ.ფოსტის მისამართი, რომელითაც მოხდა რეგისტრაციის გავლა და ჩვენ გამოგიგზავნით თქვენი პაროლის აღდგენის ინსტრუქციას.</p>
              </div>
              <form class="">
                <!-- Form Group -->
                <div class="form-group">
                  <label class="input-label" for="signin-email">ელ-ფოსტა</label>
                  <input class="form-control form-control-lg" type="email" id="signin-email" placeholder="მიუთითე შენი ელ-ფოსტა">
                </div>
                <!-- End Form Group -->

                <!-- Button -->
                <button type="submit" class="btn btn-lg btn-block btn-primary">გაგზავნა</button>
                <!-- End Button -->

                <div class="text-center mt-3">
                  <router-link to="/Signin">
                    <a href="Signin" style="font-size: 0.9rem;font-weight: bold">
                      <i class="fas fa-angle-left"></i>&nbsp; დაბრუნდი ავტორიზაციის გვერდზე
                    </a>
                  </router-link>
                </div>

              </form>
            </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Recovery"
}
</script>

<style scoped>

</style>