<template>
  <div>
      <Header />
      <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
          <div class="content container-fluid">
              <div class="page-header">
                  <div class="row align-items-end">
                      <div class="col-sm mb-2 mb-sm-0">
                          <h1 class="page-header-title">მომხმარებლის დამატება</h1>
                          <nav aria-label="breadcrumb">
                              <ol class="breadcrumb breadcrumb-no-gutter">
                                  <li class="breadcrumb-item"><router-link class="breadcrumb-link" to="/Users">მომხმარებლების სია</router-link></li>
                                  <li class="breadcrumb-item active" aria-current="page">მომხმარებლის დამატება</li>
                              </ol>
                          </nav>
                      </div>
                  </div>
                  <!-- End Row -->
              </div>


    
              <div class="row">
                  <div class="col-12">
                      <!-- Column -->
                      <div class="card">


                          <form @submit.prevent="subtmitForm()">
                              <div class="card-body">
                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label for="name" class="control-label">დასახელება</label>
                                          <input class="form-control" v-model="form.name" placeholder="დასახელება" name="name" type="text" id="name">
                                          <p class="help-block"></p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label for="email" class="control-label">ელ.ფოსტა</label>
                                          <input class="form-control"  v-model="form.email" placeholder="ელ.ფოსტა" name="email" type="email" id="email">
                                          <p class="help-block"></p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label for="password" class="control-label">პაროლი</label>
                                          <input class="form-control" v-model="form.password" placeholder="პაროლი" name="password" type="password" id="password">
                                          <p class="help-block"></p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label>ტელეფონის ნომერი</label>
                                          <input type="number" v-model="form.mobile" placeholder="ტელეფონის ნომერი" name="mobile" class="form-control">
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label>თანამდებობა</label>
                                          <input type="text" v-model="form.position" placeholder="თანამდებობა" name="position" class="form-control">
                                      </div>
                                  </div>


                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label>Facebook პროფილი</label>
                                          <input type="text" v-model="form.facebook_profile_url"  placeholder="Facebook პროფილი" name="facebook" value="" class="form-control">
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-12 form-group">
                                          <label for="roles" class="control-label">როლები</label>
                                          <select class="form-select" v-model="form.permission" style="height: auto!important;padding: 0.903rem 1.09375rem!important;">
                                              <option value="Super Administrator">სუპერ ადმინისტრატორი</option>
                                              <option value="Group Administrator">დირექტორი</option>
                                              <option value="Consultant">კონსულტანტი</option>
                                              <option value="Administrator">მოდერატორი</option>
                                              <option value="no_role">პროდუქტ მენეჯერი</option>
                                          </select>
                                          <p class="help-block"></p>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-12  form-group">
                                          <label class="control-label">დირექტორი</label>
                                          <v-select v-model="form.parent_user_id" placeholder="აირჩიე ფილიალის დირექტორი" label="name" :options="parent_users">
                                          </v-select>
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-12  form-group">
                                          <label class="control-label">ფილიალი</label>
                                          <v-select v-model="form.region" placeholder="აირჩიე ფილიალის დირექტორი" label="name" :options="Regions">
                                          </v-select>
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-12  form-group">
                                          <label class="control-label">სტატუსი</label>
                                          <v-select v-model="form.status" placeholder="აირჩიე ფილიალის დირექტორი" label="name" :options="[
                                              {id: 'active', name: 'აქტიური'},
                                              {id: 'not_active', name: 'არ არის აქტიური'},
                                              {id: 'vaction', name: 'შვებულებაშია'},
                                              {id: 'decree', name: 'დეკრეტშია'},
                                              {id: 'health', name: 'ბიულეტინზეა'}
                                              ]">
                                          </v-select>
                                      </div>
                                  </div>

                                  <input class="btn btn-danger" type="submit" value="შენახვა">

                              </div>


                          </form>

                      </div>
                  </div>
              </div>
          </div>
          <!-- End Content -->

      
          <!-- Footer -->
          <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
          <div class="row">
              <div class="col">
              <router-link to="/Deals_Overview">
                  <i class="fa fa-arrow-left"></i> უკან
              </router-link>
              </div>
          </div>
          </div>
          <!-- End Footer -->
      
      </main>
  </div> 
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
  name: "Edit",
  data() {
      return {
          form: {
              name: '',
              email: '',
              password : '',
              mobile: '',
              position: '',
              facebook_profile_url: '',
              permission: '',
              parent_user_id: '',
              region: '',
              status: {id: 'active', name: 'აქტიური'}
          },
          Regions: [],
          parent_users: []
      }
  },
  components: {
      Header
  },
  methods: {

      // submit form
      subtmitForm() {
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.put(process.env.VUE_APP_BACKEND_URL + '/auth/user/edit/'+this.$route.params.id, this.form ,{
              headers: {
                  'Authorization': `Bearer ${token}`
              },
          })
              .then(response => {
                 this.$router.push({path: "/Users"})
              });
      },

      // get user data
      getUserData() {
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/get/user/'+this.$route.params.id,{
              headers: {
                  'Authorization': `Bearer ${token}`
              },
          })
              .then(response => {
                 this.form = response.data;
              });
      },


      // regions data
      regions() {
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/get/regions', {
              headers: {
                  'Authorization': `Bearer ${token}`
              },
          })
              .then(response => {
                  this.Regions = response.data;
              });
      },

      // directors data
      parentUsers() {
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/get/nonparent/users', {
              headers: {
                  'Authorization': `Bearer ${token}`
              },
          })
              .then(response => {
                  this.parent_users = response.data;
              });
      }
  },
  mounted() {
      this.regions();
      this.parentUsers();
      this.getUserData();
  }
}
</script>

<style>
.page-header-title {
  font-size: 1.41094rem;
}
</style>