<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">
          <div class="col-7 mb-4">
            <h1 class="page-title">ვიდეოს დამატება</h1>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="form-row">

                  <!-- Col -->
                  <div class="form-group col-12">
                    <label class="input-label h5">ვიდეოს სათაური</label>
                      <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text bg-transparent px-3 text-danger" id="timer"><i class="tio-play-circle-outlined"></i></span>
                        <input type="text" class="form-control" placeholder="ვიდეოს სათაური" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                      </div>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <label class="input-label h5">ატვირთე ვიდეო</label>
                    <!-- File Attachment Input -->
                    <vue-dropzone class="custom-dropzone" ref="myVueDropzoneLogo" :useCustomSlot=true  id="dropzone-logo" :headers="dropzoneOptions.headers" v-on:vdropzone-success="uploadVideoSuccess" :options="dropzoneOptions">
                      <i class="tio-upload-on-cloud display-4 text-danger"></i>
                      <span class="text-danger d-block" id="customFileBoxedEg">ატვირთე ვიდეო</span>
                    </vue-dropzone>
                    <!-- End File Attachment Input -->
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <label class="input-label h5">დაამატე შეკითხვა</label>
                    <div class="row align-items-center">
                      <div class="col-12 col-md-10">
                        <textarea class="form-control" rows="3" placeholder="ჩაწერე შეკითხვა"></textarea>
                      </div>
                      <div class="col-12 col-md-2 mt-2 mt-md-0">
                        <button class="btn btn-danger w-100"><i class="tio-add"></i> დამატება</button>
                      </div>
                    </div>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <div class="card shadow-none">
                      <div class="card-header bg-danger text-white">
                        კითხვები: 5
                      </div>
                      <div class="card-body p-2">
                        <ul class="list-group list-group-flush list-group-no-gutters">

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-10 col-md-11 d-flex">
                                    <h5 class="mb-0 me-1">1:</h5>
                                    <span class="font-size-sm">როგორ ვიწყებთ დიქეი კაპიტალში პირველ სამუშაო დღეს გამოცდის ჩაბარების შემდეგ?</span>
                                  </div>
                                  <div class="col-2 col-md-1">
                                    <div class="btn-group dropstart">
                                      <button type="button" class="btn btn-white border-0 dropdown-toggle rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="tio-more-vertical"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delayedDate"><i class="tio-edit"></i> რედაქტირება</a>
                                        <a class="dropdown-item text-danger" href="#" data-toggle="modal" data-target="#offerReject"><i class="tio-delete"></i> წაშლა</a>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-10 col-md-11 d-flex">
                                    <h5 class="mb-0 me-1">2:</h5>
                                    <span class="font-size-sm">როგორ ვიწყებთ სამუშაო დღეს დიქეი კაპიტალში?</span>
                                  </div>
                                  <div class="col-2 col-md-1">
                                    <div class="btn-group dropstart">
                                      <button type="button" class="btn btn-white border-0 dropdown-toggle rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="tio-more-vertical"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delayedDate"><i class="tio-edit"></i> რედაქტირება</a>
                                        <a class="dropdown-item text-danger" href="#" data-toggle="modal" data-target="#offerReject"><i class="tio-delete"></i> წაშლა</a>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-10 col-md-11 d-flex">
                                    <h5 class="mb-0 me-1">3:</h5>
                                    <span class="font-size-sm">რატომ ჯობია კლიენტზე ფოკუსირება?</span>
                                  </div>
                                  <div class="col-2 col-md-1">
                                    <div class="btn-group dropstart">
                                      <button type="button" class="btn btn-white border-0 dropdown-toggle rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="tio-more-vertical"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delayedDate"><i class="tio-edit"></i> რედაქტირება</a>
                                        <a class="dropdown-item text-danger" href="#" data-toggle="modal" data-target="#offerReject"><i class="tio-delete"></i> წაშლა</a>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-10 col-md-11 d-flex">
                                    <h5 class="mb-0 me-1">4:</h5>
                                    <span class="font-size-sm">რა შედეგის მოტანა შეუძლია 1 კლიენტს კონსულტანტისთვის და მასზე ფოკუსირებას?</span>
                                  </div>
                                  <div class="col-2 col-md-1">
                                    <div class="btn-group dropstart">
                                      <button type="button" class="btn btn-white border-0 dropdown-toggle rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="tio-more-vertical"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delayedDate"><i class="tio-edit"></i> რედაქტირება</a>
                                        <a class="dropdown-item text-danger" href="#" data-toggle="modal" data-target="#offerReject"><i class="tio-delete"></i> წაშლა</a>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                          <!-- List Group Item -->
                          <li class="list-group-item py-2 px-2">
                            <div class="media">
                              <div class="media-body">
                                <div class="row align-items-center">
                                  <div class="col-10 col-md-11 d-flex">
                                    <h5 class="mb-0 me-1">5:</h5>
                                    <span class="font-size-sm">რა გავაკეთოთ როდესაც კლიენტს უნდა დეველოპერისგან უძრავი ქონების შეძენა და ჩვენი პარტნიორი დეველიპერული კომპანიის შეთავაზებები ვერ მივყიდეთ?</span>
                                  </div>
                                  <div class="col-2 col-md-1">
                                    <div class="btn-group dropstart">
                                      <button type="button" class="btn btn-white border-0 dropdown-toggle rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="tio-more-vertical"></i>
                                      </button>
                                      <ul class="dropdown-menu">
                                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delayedDate"><i class="tio-edit"></i> რედაქტირება</a>
                                        <a class="dropdown-item text-danger" href="#" data-toggle="modal" data-target="#offerReject"><i class="tio-delete"></i> წაშლა</a>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <!-- End Row -->
                              </div>
                            </div>
                          </li>
                          <!-- End List Group Item -->

                        </ul>

                      </div>
                    </div>
                  </div>
                  <!-- End Col -->

                </div>
              </div>
              <div class="card-footer text-end p-3">
                <router-link to="/Videos">
                  <a href="Videos" class="btn btn-white me-1"><i class="tio-back-ui"></i> გაუქმება</a>
                </router-link>
                <button class="btn btn-danger"><i class="tio-save"></i> შენახვა</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from '../../../components/Header.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "AddVideoMotivation",
  components: {
    Header,
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: '#',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,
      }
    }
  },
  methods: {
    uploadVideoSuccess: function(file, response) {
      this.form.file=response.success;
    },
  }
}
</script>

<style scoped>
.dropstart .dropdown-toggle::before {
  display: none;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 98;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 1rem 0;
  margin: 0.625rem 0 0;
  font-size: .875rem;
  color: #677788;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0,0,0,.15);
  border-radius: 0.75rem;
  box-shadow: 0 10px 40px 10px rgb(140 152 164 / 18%);
}
</style>