<template>
    <div>
      <Header/>
      <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
        <div class="content container-fluid academy">
          <!-- Row -->
          <div class="row align-items-center">

            <div class="col-sm">
              <h1 class="page-title mb-1">მომხმარებლები</h1>
            </div>

            <div class="col-sm-auto">
                <router-link class="btn btn-primary" to="/Create">
                    <i class="tio-user-add mr-1"></i>  დამატება
                </router-link>
            </div>
  
            <!-- Breadcrumb -->
            <div class="col-12 mb-2 d-none d-md-block">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <!-- Offers Status ---------->
                  <li class="breadcrumb-item active" aria-current="page">მომხმარებლები სია</li>
                </ol>
              </nav>
            </div>
            <!-- End Breadcrumb -->
  
          </div>
          <!-- End Row -->

          <div class="row gx-2 gx-lg-3">
                <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                    <!-- Card -->
                    <div class="card h-100">
                        <div class="card-body">
                            <h6 class="card-subtitle mb-2">სულ მომხმარებლები</h6>

                            <div class="row align-items-center gx-2">
                                <div class="col">
                                    <span class="js-counter display-4 text-dark" data-value="951">{{ user_status_count.user_count }}</span>
                                </div>
                            </div>
                            <!-- End Row -->
                        </div>
                    </div>
                    <!-- End Card -->
                </div>

                <div class="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                    <!-- Card -->
                    <div class="card h-100">
                        <div class="card-body">
                            <h6 class="card-subtitle mb-2">აქტიური მომხმარებლები</h6>

                            <div class="row align-items-center gx-2">
                                <div class="col">
                                    <span class="js-counter display-4 text-dark" data-value="203">{{ user_status_count.active_users }}</span>
                                </div>
                            </div>
                            <!-- End Row -->
                        </div>
                    </div>
                    <!-- End Card -->
                </div>


                <div class="col-12">
                  <div class="card">
                <!-- Table -->
                <div class="table-responsive user-table">
                    <table class="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle">
                        <thead class="bg-light">
                        <tr>
                            <th>ID</th>
                            <th class="d-max-none">სახელი</th>
                            <th class="d-max-none">ფერმიშენები</th>
                            <th class="d-max-none">ბრენჩი</th>
                            <th class="d-max-none">სტატუსი</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in users.data" :key="index">
                            <td>
                              <span v-if="item.status.id == 'active'" class="legend-indicator bg-success"></span>
                              <span v-else class="legend-indicator bg-danger"></span>
                              {{ item.id }}
                            </td>
                            <td class="table-column-pl-0">
                                <router-link :to="'/edit_user/'+item.id" class="d-flex align-items-center">
                                    <div class="avatar avatar-circle">
                                        <img class="avatar-img" src="https://dkhome.ge/assets/img/160x160/img10.jpg" alt="Image Description">
                                    </div>
                                    <div class="ms-3">
                                        <span class="d-block h5 text-hover-primary mb-0">{{ item.name }}</span>
                                        <span class="d-block font-size-sm text-body">{{ item.email }}</span>
                                    </div>
                                </router-link>
                            </td>
                            <td class="d-max-none">
                              <span class="d-block h5 mb-0">{{ item.permission }}</span>
                            </td>
                            <td class="d-max-none">
                              თბილისი
                            </td>
                            <td class="d-max-none">
                              <div class="btn-group">
                                <button class="btn btn-ghost-success dropdown-toggle border-0"  type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <span v-if="item.status.id == 'active'" class="legend-indicator bg-success"></span>
                                  <span v-else class="legend-indicator bg-danger"></span>
                                  <span v-if="item.status.id == 'active'">აქტიური</span>
                                  <span v-if="item.status.id == 'not_active'">არ არის აქტიური</span>
                                  <span v-if="item.status.id == 'vaction'">შვებულებაშია</span>
                                  <span v-if="item.status.id == 'decree'">დეკრეტშია</span>
                                  <span v-if="item.status.id == 'health'">ბიულეტინზეა</span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#00c9a7"><path d="M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z"/></svg>
                                </button>
                                <ul class="dropdown-menu">
                                  <div class="dropdown-item cursor-pointer" @click="userStatus('active', item.id)">
                                      <span class="legend-indicator bg-success"></span>
                                      <span class="text-dark">აქტიური</span>
                                  </div>
                                  <div class="dropdown-item cursor-pointer" @click="userStatus('not_active', item.id)">
                                      <span class="legend-indicator bg-danger"></span>
                                      <span class="text-dark">არ არის აქტიური</span>
                                  </div>
                                  <div class="dropdown-item cursor-pointer" @click="userStatus('vaction', item.id)">
                                      <span class="legend-indicator bg-danger"></span>
                                      <span class="text-dark">შვებულებაშია</span>
                                  </div>
                                  <div class="dropdown-item cursor-pointer" @click="userStatus('decree', item.id)">
                                      <span class="legend-indicator bg-danger"></span>
                                      <span class="text-dark">დეკრეტშია</span>
                                  </div>
                                  <div class="dropdown-item cursor-pointer" @click="userStatus('health', item.id)">
                                      <span class="legend-indicator bg-danger"></span>
                                      <span class="text-dark">ბიულეტინზეა</span>
                                  </div>
                                </ul>
                              </div>
                              <!-- End Dropdown -->
                            </td>
                            <td class="d-max-none">
                                <router-link class="btn btn-sm btn-white" :to="'/edit_user/'+item.id">
                                    <i class="tio-edit"></i> <span class="d-none d-sm-inline-block">რედაქტირება</span>
                                </router-link>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <!-- End Table -->

                <!-- Footer -->
                <div class="card-footer">
                    <!-- Pagination -->
                    <div class="row">
                      <div class="col-md-12 mt-4 mb-5">
                        <div class="pagination-container">
                          <pagination :limit="3" show-disabled :data="users" @pagination-change-page="loadUsers()">
                            <template #prev-nav>
                              <span>წინ</span>
                            </template>
                            <template #next-nav>
                              <span>შემდეგ</span>
                            </template>
                          </pagination>
                        </div>
                      </div>
                    </div>
                    <!-- End Pagination -->
                </div>
                <!-- End Footer -->
            </div>
                </div>
            </div>

        </div>
        <!-- End Content -->

  
        <!-- Footer -->
        <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
          <div class="row">
            <div class="col">
              <router-link to="/Deals_Overview">
                <i class="fa fa-arrow-left"></i> უკან
              </router-link>
            </div>
          </div>
        </div>
        <!-- End Footer -->
  
      </main>
      <!-- End Main -->
  
      <!-- offcanvas Ritail Clients Filter -->
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasClosingDeals" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header border-bottom">
          <h4 id="offcanvas-title">ფილტრი</h4>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <form class="row">
            <div class="col-12 form-group">
              <label class="h5">დილის ID</label>
              <input class="form-control" type="number" placeholder="დილის ID">
            </div>
            <div class="col-12 form-group">
              <label class="h5">პროდუქტის ტიპი </label>
              <v-select placeholder="აირჩიე პროექტის ტიპი" :options="[
                'ბინები',
                'სასტუმროები',
                'კომერციული ფართები',
                'მიწის ნაკვეთები',
                'სახლები და აგარაკები'
              ]"></v-select>
            </div>
            <div class="col-12 form-group">
              <label class="h5">უძრავი ქონების სტატუსი </label>
              <v-select placeholder="უძრავი ქონების სტატუსი" :options="[
                'ახალი აშენებული',
                'მშენებარე',
                'ძველი აშენებული'
              ]"></v-select>
            </div>
            <div class="col-12 form-group">
              <label class="h5">უძრავი ქონების კონდიცია </label>
              <v-select placeholder="უძრავი ქონების კონდიცია" :options="[
                'თეთრი კარკასი',
                'შავი კარკასი',
                'მწვანე კარკასი',
                'ახალი რემონტი',
                'ძველი რემონტი'
              ]"></v-select>
            </div>
            <div class="col-12 form-group">
              <label class="h5">საძინებლების რაოდენობა </label>
              <v-select multiple placeholder="აირჩიე საძინებლების რაოდენობა" :options="[
                0,
                1,
                2,
                3,
                4
              ]"></v-select>
            </div>
            <div class="col-12">
              <label class="h5">ფასი </label>
            </div>
            <div class="col-6 form-group">
              <label>მინიმალური($)</label>
              <input type="number" class="form-control" placeholder="-დან">
            </div>
            <div class="col-6 form-group">
              <label>მაქსიმალური($)</label>
              <input type="number" class="form-control" placeholder="-მდე">
            </div>
            <div class="col-12">
              <label class="h5">ფართის მოცულობა </label>
            </div>
            <div class="col-6 form-group">
              <label>მინიმალური</label>
              <input type="number" class="form-control" placeholder="-დან">
            </div>
            <div class="col-6 form-group">
              <label>მაქსიმალური</label>
              <input type="number" class="form-control" placeholder="-მდე">
            </div>
  
            <div class="col-12 form-group">
              <label class="h5">ფილიალის დირექტორი </label>
              <v-select multiple placeholder="აირჩიე ფილიალის დირექტორი" :options="[
                'სალომე ჩემია',
                'ეკა მოლაშვილი',
                'ნინო მდივანი'
              ]"></v-select>
            </div>
            <div class="col-12 form-group">
              <label class="h5">გაყიდვების მენეჯერი </label>
              <v-select multiple placeholder="აირჩიე გაყიდვების მენეჯერი" :options="[
                'მენეჯერი 1',
                'მენეჯერი 2',
                'მენეჯერი 3'
              ]"></v-select>
            </div>
  
          </form>
        </div>
        <div class="offcanvas-footer p-3 border-top bg-white">
          <div class="row">
            <div class="col-6">
              <button type="button" class="btn btn-block btn-primary"><i class="fa fa-search me-1"></i> შესრულება</button>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-block btn-white"><i class="tio-remove-from-trash text-secondary me-1"></i> გასუფთავება</button>
            </div>
          </div>
        </div>
      </div>
      <!-- End offcanvas Ritail Clients Filter -->
  
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue'
  import axios from 'axios';
  
  export default {
    name: "Closing",
    data() {
      return {
        users: {},
        user_Status: {},
        user_status_count: ''
      }
    },
    components: {
      Header
    },
    methods: {
      loadUsers(page = 1){
        
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/users/'+'?page='+page,{
          headers: {
            'Authorization': `Bearer ${token}`
          },
          // params:{
          //   name:this.$route.query.name,
          //   id:this.$route.query.id,
          //   position:this.$route.query.position,
          //   region:this.$route.query.region,
          //   min_budget:this.$route.query.min_budget,
          //   max_budget:this.$route.query.max_budget,
          //   start_at:this.$route.query.start_at,
          //   end_at:this.$route.query.end_at,
          //   payment_status:this.$route.query.payment_status
          // }
        })
            .then(response => {
              window.scroll(0, 0);
              this.users=response.data;
  
           
              this.isLoading = false;
            });
      },

      // user staatus count
      getUserCount() {
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/get/active/clients/count',{
              headers: {
                  'Authorization': `Bearer ${token}`
              },
          })
              .then(response => {
                 this.user_status_count = response.data;
              });
      },


      userStatus(status, id) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/user/status/change/'+id, {status: status},{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              window.location.reload();
          
  
           
              this.isLoading = false;
            });
      }

  
    },
    mounted() {
     this.loadUsers();
     this.getUserCount();
  
    },
  }
  </script>
  
  <style scoped>
  .border-custom-hover {
    border: 1px solid #d5d5d5 !important;
    transition: 0.3s;
  }
  .border-custom-hover:hover {
    border: 1px solid #727a86 !important;
  }

  .avatar:not(img) {
    background-color: #fff;
}

.avatar-circle {
    border-radius: 50%;
}
.avatar {
    position: relative;
    display: inline-block;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 0.3125rem;
}

.avatar-circle .avatar-img, .avatar-circle .avatar-initials {
    border-radius: 50%;
}

.avatar-img {
    display: block;
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    border-radius: 0.3125rem;
}


.legend-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #bdc5d1;
    border-radius: 50%;
    margin-right: 0.4375rem;
}

.user-table {
  min-height: 300px;
}
.user-table tbody tr td {
  vertical-align: middle;
}

.user-table thead th {
  padding: 20px 0.5rem;
}
  </style>