import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tio-icon.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/style.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Select2 from 'v-select2-component';

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
// Form Validation
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full';
import {ValidationObserver} from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Laravel Pagination
Vue.component('pagination', require('laravel-vue-pagination'));


import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Google Maps

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDp3Ud3SixagW8bJOwtosy5bBMB5JRNS_k",
  },
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Date picker
Vue.use(DatePicker)

Vue.component('Select2', Select2);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false

/* Fonts */
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all'


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
