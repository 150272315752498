<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title">კორპორატიული დილის დამატება</h1>
          </div>

          <!-- Col -->
          <div class="col-12 mt-4">
            <div class="card">
              <div class="card-header d-flex align-items-center text-dark font-weigt-bold">
                <h1 class="me-3 mb-0">03</h1>
                <div> <h4 class="mb-0">შეავსე დილის ინფორმაცია</h4> <span class="text-primary" v-b-tooltip.hover title="კორპორატიული კლიენტი"> თიბისი ბანკი</span></div>
              </div>
              <div class="card-body">
                <!-- Row -->
                <form class="row">
                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5">პროდუქტის ტიპი</label>
                    <v-select placeholder="აირჩიე პროდუქტის ტიპი" :options="[
                        'იყიდება',
                        'ქირავდება',
                        'საინვესტიციო'
                        ]">
                    </v-select>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5">უძრავი ქონების ტიპი </label>
                    <v-select placeholder="აირჩიე უძრავი ქონების ტიპი" :options="[
                        'ბინები',
                        'სასტუმროები',
                        'კომერციული ფართები',
                        'მიწის ნაკვეთები',
                        'სახლები და აგარაკები'
                        ]">
                    </v-select>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="h5">საძინებლების რაოდენობა </label>
                    <v-select multiple placeholder="აირჩიე საძინებლების რაოდენობა" :options="[
                          0,
                          1,
                          2,
                          3,
                          4
                        ]">
                    </v-select>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="col-12">
                    <label for="minAmount" class="h5">ფასი </label>
                  </div>
                  <div class="form-group col-6">
                    <label for="minAmount">მინიმალური ($)</label>
                    <input id="minAmount" type="number" class="form-control" placeholder="-დან">
                  </div>
                  <div class="form-group col-6">
                    <label for="maxAmount">მაქსიმალური ($)</label>
                    <input id="maxAmount" type="number" class="form-control" placeholder="-მდე">
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <router-link to="/Finish_Corporate_Deal">
                      <button class="btn btn-primary"><i class="tio-next-ui"></i> შემდეგი</button>
                    </router-link>
                  </div>
                  <!-- End Col -->
                </form>
                <!-- End Row -->
              </div>
            </div>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Corporate_Client_Identification">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: "Open_Corporate_Deal",
  components: {
    Header
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
}
</script>

<style scoped>

</style>