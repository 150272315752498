<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-12">
            <h1 class="page-title mb-1">{{ client.name +" "+ client.surname }}</h1>
          </div>

          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/Retail_Clients">რითეილ კლიენტები</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">დეტალები</li>
              </ol>
            </nav>
          </div>

          <!-- Create Test -->
          <div class="col-12 mt-3">
            <div class="card">
              <div class="card-header bg-custom-primary d-flex justify-content-between text-white">
                <span class="font-weigt-bold ">კლიენტის მონაცემები</span>
                <span class="font-weigt-bold">ID: {{ client.id }}</span>
              </div>
              <div class="card-body">
                <table class="table table-borderless">
                  <tbody>
                  <tr>
                    <td class="d-flex flex-column">
                      <span class="font-weigt-bold mb-2">{{ client.name +" "+ client.surname }} </span>
                      <div class="d-flex" v-if="client.ge_mobile">
                        <span v-if="client.ge_mobile">{{ client.ge_mobile }}</span> &nbsp;<span v-if="client.ge_second_mobile">/</span>&nbsp; <span v-if="client.ge_second_mobile">{{ client.ge_second_mobile }}</span>
                      </div>
                      <div class="d-flex" v-if="client.foreigner_mobile">
                        <span v-if="client.foreigner_mobile">{{ client.foreigner_mobile }}</span> &nbsp;<span v-if="client.foreigner_mobile">/</span>&nbsp; <span v-if="client.foreigner_second_mobile">{{ client.foreigner_second_mobile }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">ელ.ფოსტა</span>
                      {{ client.email }}
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">პირადი ნომერი</span>
                      {{ client.personal_id }} 
                      {{ client.foreigner_personal_id }} 
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">კლიენტის რეგისტრაციის ავტორი</span>
                      {{ client.author_full_name }} 
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex flex-column py-3">
                      <span class="font-weigt-bold mb-2">რეგისტრაციის თარიღი</span>
                      {{ client.created_at }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-3">
                      <router-link :to="'/Client_edit/'+this.$route.params.id" class="btn btn-secondary me-1"><i class="tio-edit"></i> შეცვლა</router-link>
                      <router-link :to="'/Deals_Overview/'+this.$route.params.id" class="btn btn-primary"><i class="fa fa-eye"></i> დილების ნახვა</router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- End Create Test -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">
            <router-link to="/Retail_Clients">
              <i class="fa fa-arrow-left"></i> უკან
            </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->

    <!-- Modal -->
    <div class="modal fade" id="clientHiostory" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">დილების ისტორია</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">დახურვა</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
  name: "Retail_Client_Details",
  data() {
    return {
      client: {}
    }
  },
  components: {
    Header
  },
  methods: {
    getClientData() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/client/get/'+this.$route.params.id, config)
        .then((res) => {
          this.client = res.data
        })
        .catch((error) => {
          var error_response = error.response.data;
          if (error.response.status == 422) {
            this.setErrors(error.response.data.errors);
          } else {
            this.setErrors(error.response.data.message);
          }
        });
    }
  },
  mounted() {
    this.getClientData();
  }
}
</script>

<style scoped>

</style>