<template>
  <div>
    <Header/>
    <main class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="content container-fluid academy">
        <!-- Row -->
        <div class="row align-items-center">


          <div class="col-8">
            <h1 v-if="this.$route.params.status == 'liked'" class="page-title mb-1">მოწონებული</h1>
            <h1 v-if="this.$route.params.status == 'meetings'" class="page-title mb-1">საპრეზენტაციო</h1>
            <h1 v-if="this.$route.params.status == 'offers'" class="page-title mb-1">განსახილველი</h1>
            <h1 v-if="this.$route.params.status == 'looking_for'" class="page-title mb-1">შეთავაზების გარეშე</h1>
            <h1 v-if="this.$route.params.status == 'won'" class="page-title mb-1">გაყიდული</h1>
            <h1 v-if="this.$route.params.status == 'lost'" class="page-title mb-1">დაკარგული</h1>
            <h1 v-if="this.$route.params.status == 'reject'" class="page-title mb-1">უარყოფილი</h1>
          </div>

          <div class="col-4 text-end">
            <router-link :to="`/Retail_Client_Information_edit/`+this.$route.params.id">
              <div class="btn btn-light"> <span class="d-none d-md-inline-block">პარამეტრები</span> <i class="tio-filter-outlined"></i> </div>
            </router-link>
          </div>

          <div class="col-12 mb-2 d-none d-md-block">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/Deals_Overview">ჩემი დილები</router-link></li>
                <!-- Deal Status ---------->
                <li v-if="this.$route.params.status == 'liked'" class="breadcrumb-item"><router-link :to="'/Deals/'+this.$route.params.status">მოწონებული დილები</router-link></li>
                <li v-if="this.$route.params.status == 'meetings'" class="breadcrumb-item"><router-link :to="'/Deals/'+this.$route.params.status">საპრეზენტაციო დილები</router-link></li>
                <li v-if="this.$route.params.status == 'offers'" class="breadcrumb-item"><router-link :to="'/Deals/'+this.$route.params.status">განსახილველი დილები</router-link></li>
                <li v-if="this.$route.params.status == 'looking_for'" class="breadcrumb-item"><router-link :to="'/Deals/'+this.$route.params.status">შეთავაზების დილები</router-link></li>
                
                <li v-if="this.$route.params.status == 'won'" class="breadcrumb-item"><router-link :to="'/Deals/'+this.$route.params.status">გაყიდული დილები</router-link></li>
                <li v-if="this.$route.params.status == 'lost'" class="breadcrumb-item"><router-link :to="'/Deals/'+this.$route.params.status">დაკარგული დილები</router-link></li>
                <!-- Client Name ---------->
          
                <!-- Go Back Liked -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>

                <!-- Go Back Meetings -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Go Back Offer -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Go Back won -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Go Back lost -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>

                <!-- Offers Status ---------->
                <li v-if="this.$route.params.status == 'liked'"  class="breadcrumb-item active" aria-current="page">მოწონებული - უძ/ქ</li>
                <li v-if="this.$route.params.status == 'reject'" class="breadcrumb-item active" aria-current="page">უარყოფილი - უძ/ქ</li>
                <li v-if="this.$route.params.status == 'meetings'" class="breadcrumb-item active" aria-current="page">საპრეზენტაციო - უძ/ქ</li>
                <li v-if="this.$route.params.status == 'offers'" class="breadcrumb-item active" aria-current="page">გადასარჩევი - უძ/ქ desktop</li>
              </ol>
            </nav>
          </div>

          <div class="col-12 mb-2 d-block d-md-none">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <!-- Liked Name -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'liked'" class="breadcrumb-item"><router-link :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Meetings Name -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'meetings'" class="breadcrumb-item"><router-link :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Offers Name -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'offers'" class="breadcrumb-item"><router-link :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Won Name -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'won'" class="breadcrumb-item"><router-link :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <!-- Lost Name -->
                <li v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>
                <li v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'lost'" class="breadcrumb-item"><router-link :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">{{deal_clien_name}}</router-link></li>

                <!-- Offers Status -->
                <li v-if="this.$route.params.status == 'liked'"  class="breadcrumb-item active" aria-current="page">მოწონებული - უძ/ქ</li>
                <li v-if="this.$route.params.status == 'reject'" class="breadcrumb-item active" aria-current="page">უარყოფილი - უძ/ქ</li>
                <li v-if="this.$route.params.status == 'meetings'" class="breadcrumb-item active" aria-current="page">საპრეზენტაციო - უძ/ქ</li>
                <li v-if="this.$route.params.status == 'offers'" class="breadcrumb-item active" aria-current="page">გადასარჩევი - უძ/ქ mobile</li>
              </ol>
            </nav>
          </div>


          <!-- Col -->
          <div class="col-12 col-md-6 mb-2" v-for="item in offersData" :key="item.id">
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <div class="col-12 mb-2">
                    <a href="#" class="d-block" :style="{
                      height: '300px',
                      overflow: 'hidden',
                      width: '100%',
                      backgroundImage: `url(${item.applications[0].first_image})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      border: '1px solid #f6f6f6',
                      borderRadius: '5px'}">
                    </a>
                  </div>

                  <div class="col-4">
                    {{ item.applications[0].price }}$
                  </div>
                  <div class="col-4">
                    მ²  {{ item.applications[0].area }}
                  </div>
                  <div class="col-4">
                    {{ item.applications[0].area_price }}კვ.მ
                  </div>
                  <div class="col-4 mt-3">
                    სართ. {{ item.applications[0].floor }}
                  </div>
                  <div class="col-4 mt-3">
                    საძ. {{ item.applications[0].bedrooms }}
                  </div>
                  <div class="col-4 mt-3">
                    ოთახ. {{ item.applications[0].rooms }}
                  </div>
                  <div class="col-12 d-flex justify-content-between align-items-center border-top pt-3 mt-3">
                    <a :href="'tel:'+item.applications[0].phone" class="btn btn-primary"><i class="tio-call"></i> {{ item.applications[0].ownername }} </a>
                    <!-- End Property Settings -->
                    <div class="d-flex">
                      <button  @click="status(item.application_id)" v-if="item.deal_status != 'liked' && item.deal_status != 'looking_for' && item.deal_status != 'reject'" class="btn btn-custom-success me-1 d-none d-md-inline-block"><i class="tio-thumbs-up"></i></button>                     
                      <button @click="rejectOffer(item.application_id)" v-if="item.deal_status != 'looking_for' && item.deal_status != 'reject'" class="btn btn-custom-danger me-1 d-none d-md-inline-block" data-bs-toggle="modal" data-bs-target="#disLike"><i class="tio-thumbs-down"></i></button>
                      <button v-if="item.deal_status == 'reject'" @click="restore_offer(item.application_id)" class="btn btn-success me-1"><i class="tio-restore"></i> აღდგენა</button>

                      <!-- Property Settings -->
                      <button type="button" class="btn btn-outline-light text-secondary dropdown-toggle " id="propertySetting" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="tio-more-vertical"></i>
                      </button>
                      <ul class="dropdown-menu text-start py-2 me-0 me-md-2 mt-2 rounded-3 border-light" aria-labelledby="#propertySetting" style="box-shadow: 0 10px 40px 10px rgb(140 152 164 / 18%);">
                        <label class="ps-3 h5">ქმედებები</label>
                        <hr class="mx-0 my-0">
                        <div class="list-group d-none">
                          <button   @click="status(item.application_id)" v-if="item.deal_status != 'liked' && item.deal_status != 'looking_for'" type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3 text-custom-success d-md-none"><i class="tio-thumbs-up"></i> მოეწონა</button>
                        </div>
                        <div class="list-group d-none">
                          <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3 text-custom-danger d-md-none" data-bs-toggle="modal" data-bs-target="#disLike"><i class="tio-thumbs-down"></i> არ მოეწონა</button>
                        </div>
                        <div class="list-group">
                          <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3 text-primary" data-bs-toggle="modal" data-bs-target="#smsNotification"><i class="tio-send-outlined"></i> შეტყობინების გაგზავნა</button>
                        </div>
                        <div class="list-group">
                          <button type="button" @click="copy()" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3 "><i class="tio-copy"></i> ლინკის კოპირება</button>
                          <input class="d-none" type="text" v-model="copyText">
                        </div>
                        <div class="list-group">
                          <a href="#" type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3 "><i class="fa fa-edit"></i> დეტალური ნახვა</a>
                        </div>
                        <div class="list-group">
                          <a href="#" type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3 "><i class="fa fa-eye"></i> ვებ-გვერდზე ნახვა</a>
                        </div>
                        <div class="list-group">
                          <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm px-3" data-bs-toggle="modal" data-bs-target="#realEstateLog"><i class="tio-format-points"></i> ლოგი</button>
                        </div>
                        <hr class="mx-0 my-0">
                        <div class="list-group">
                          <div class="py-2 pb-2">
                            <label class="px-3 h5">სტატუსის ცვლილება</label>
                            <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm w-100 text-custom-success px-3 custom-active">
                              <i class="tio-checkmark-circle"></i> აქტიური
                            </button>
                            <button type="button" class="list-group-item list-group-item-action border-0 rounded-0 font-size-sm w-100 text-custom-danger px-3" data-bs-toggle="modal" data-bs-target="#realEstateStatus">
                              <i class="tio-clear-circle-outlined"></i> არა აქტიური
                            </button>
                          </div>
                        </div>
                        <hr class="mx-0 my-0">
                        <div class="list-group">
                          <div class="px-3 py-2">
                            <label class="h5">ქონების სტატუსის ბოლო გადამოწმება</label><br>
                            <small><i class="tio-edit"></i> სალომე ჩემია</small><br>
                            <small><i class="tio-date-range"></i> 01.01.2022</small>
                          </div>
                        </div>
                        <div class="list-group">
                          <div class="px-3 py-2">
                            <label class="h5">ამ ეტაპზე გამოსვლის თარიღი</label><br>
                            <small><i class="tio-date-range"></i> 01.01.2022</small>
                          </div>
                        </div>
                      </ul>

                    </div>
                    
                  </div>
                  <div class="col-12 d-flex mt-1 d-md-none">
            
                    <button @click="status(item.application_id)" v-if="item.deal_status != 'liked' && item.deal_status != 'looking_for' && item.deal_status != 'reject'" class="btn btn-custom-success me-1"><i class="tio-thumbs-up"></i> მოეწონა</button>
                      <button @click="rejectOffer(item.application_id)" class="btn btn-custom-danger me-1" data-bs-toggle="modal" v-if="item.deal_status != 'looking_for' && item.deal_status != 'reject'" data-bs-target="#disLike"><i class="tio-thumbs-down"></i> არ მოეწონა</button>

                  </div>
                  <div class="col-12 border-top  mt-3" v-if="item.deal_status === 'liked'">
                    
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <tbody>

                        <!-- item -->
                        <tr v-for="(items, index) in handBookList" :key="index">
                          <td class="align-middle ps-0 pe-2">
                            <div class="custom-control custom-checkbox-switch">
                              <input :disabled="item.dones.includes(items.id)" @click="check_HandBook_Task(item.application_id, item.id, items.id)" :value="items.id" v-model="item.checklist" type="checkbox" :id="items.id" class="custom-control-input custom-checkbox-switch-input">
                              <label  class="custom-checkbox-switch-label btn-icon btn-todo rounded-circle" :for="items.id">
                                    <span class="custom-checkbox-switch-default">
                                      <i class="tio-circle"></i>
                                    </span>
                                    <span class="custom-checkbox-switch-active">
                                          <i class="tio-add"></i>
                                    </span>
                              </label>
                            </div>
                          </td>
                          <td class="align-middle px-1 w-100 todo-title">
                            <s v-if="item.dones.includes(items.id)">{{ items.name }}</s>
                            <span v-else>{{ items.name }}</span>
                          </td>
                          <td class="align-middle px-0">
                            <button @click="done_handbook_task(item.application_id, item.id, items.id)" v-if="item.checklist.includes(items.id)" class="btn btn-primary px-2 py-1"><i class="tio-done"></i></button>
                          </td>
                        </tr>
                        <!-- End item -->

                        <tr v-if="item.dones.length == 1">
                          <td class="text-center align-middle" colspan="3">
                            <button class="btn btn-custom-success" data-bs-toggle="modal" :data-bs-target="`#Won_${item.id}`"><i class="tio-dollar-outlined"></i> გაყიდვა</button>
                            <!-- Won -->
                            <div class="modal fade" :id="`Won_${item.id}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">გაყიდვა</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                    ნამდვილად გსურთ ამ ქმედების განხორციელება?
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
                                    <button type="button" data-bs-dismiss="modal" @click="Won(item.application_id, item.id)" class="btn btn-custom-success"><i class="tio-dollar-outlined"></i> თანხმობა</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- End Col -->

        </div>
        <!-- End Row -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="footer" :class="this.$store.state.showTopMenu== true ? 'collapsed' : ''">
        <div class="row">
          <div class="col">

            <!-- liked Status ---------->
            <router-link v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'liked'" :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'liked'" :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'liked'" :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'liked'" :to="'/Details/'+'liked'+'/'+'liked'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>

            <!-- meetings Status ---------->
            <router-link v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'meetings'" :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'meetings'" :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'meetings'" :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'meetings'" :to="'/Details/'+'meetings'+'/'+'meetings'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>

            <!-- Offers Status ---------->
            <router-link v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'offers'" :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'offers'" :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'offers'" :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'offers'" :to="'/Details/'+'offers'+'/'+'offers'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>

            <!-- won Status ---------->
            <router-link v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'won'" :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'won'" :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'won'" :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'won'" :to="'/Details/'+'won'+'/'+'won'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>

            <!-- lost Status ---------->
            <router-link v-if="this.$route.params.status == 'liked' && this.$route.params.addition == 'lost'" :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'reject' && this.$route.params.addition == 'lost'" :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'meetings' && this.$route.params.addition == 'lost'" :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
            <router-link v-if="this.$route.params.status == 'offers' && this.$route.params.addition == 'lost'" :to="'/Details/'+'lost'+'/'+'lost'+'/'+this.$route.params.id">  <i class="fa fa-arrow-left"></i> უკან </router-link>
          </div>
        </div>
      </div>
      <!-- End Footer -->

    </main>
    <!-- End Main -->




    <!-- Action Modals -->

    <!-- Dislike -->
    <div class="modal fade" id="disLike" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">არ მოეწონა?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ ამ ქმედების განხორციელება?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
            <button @click="rejectOffer_done(rejectOffer_ID)" data-bs-dismiss="modal" type="button" class="btn btn-custom-danger"><i class="tio-thumbs-down"></i> თანხმობა</button>
          </div>
        </div>
      </div>
    </div>

    <!-- sms Notification -->
    <div class="modal fade" id="smsNotification" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">შეტყობინების გაგზავნა</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ შეტყობინების გაგზავნა?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
            <button type="button" class="btn btn-primary"><i class="tio-send-outlined"></i> გაგზავნა</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Real Estate Log -->
    <div class="modal fade" id="realEstateLog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">უძრავი ქონების ლოგი</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ლოგის ადგილი
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">დახურვა</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Real Estate Status -->
    <div class="modal fade" id="realEstateStatus" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">უძრავი ქონების სტატუსის ცვლილება</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ ამ ქმედების განხორციელება?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
            <button type="button" class="btn btn-custom-danger"><i class="tio-clear-circle-outlined"></i> თანხმობა</button>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios';


export default {
  name: "Offers_Details",
  data() {
    return {
      copyText: 'https://dkhome.ge/show/10000',
      offersData: [],
      deal_clien_name: [],
      rejectOffer_ID: '',
      handBookList: []
    }
  },
  components: {
    Header
  },
  methods: {
    copy: function () {
      this.$copyText(this.copyText).then(function (e) {
        alert('ლინკი დაკოპირებულია')
        console.log(e)
      }, function (e) {
        alert('ლინკის დაკოპირება ვერ მოხერხდა')
        console.log(e)
      })
    },
    offers(){
      
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/offers/'+this.$route.params.status+"/"+this.$route.params.id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
           
            this.offersData=response.data;
            this.deal_clien_name=response.data[0].name;

         
            // this.isLoading = false;
          });
    },

    status(id){
      
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/offer/change/status/'+this.$route.params.status+"/"+this.$route.params.id+"/"+id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.offers();
           
          });
    },
    rejectOffer(id){
      this.rejectOffer_ID = id;
    },
    rejectOffer_done(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/reject/'+id+"/"+this.$route.params.id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.offers();
           
          });
    },
    restore_offer(id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/offer/restore/'+id+"/"+this.$route.params.id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.offers();
           
          });
    },

    // HandBook List
    handBookData() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/handbooks', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.handBookList = response.data
           
          });
    },
    check_HandBook_Task(app_id, offer_id, hand_Id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/handbook/mark/'+app_id+'/'+offer_id+'/'+this.$route.params.id+'/'+hand_Id, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
             console.log(response)
          });
    },
    done_handbook_task(app_id, offer_id, hand_Id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.put(process.env.VUE_APP_BACKEND_URL+'/auth/handbook/mark/done/'+app_id+'/'+offer_id+'/'+this.$route.params.id+'/'+hand_Id, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
             this.offers();
          });
    },
    Won(app_id, offer_id) {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.put(process.env.VUE_APP_BACKEND_URL+'/auth/sell/deal/'+this.$route.params.id+'/'+offer_id+'/'+app_id, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.offers();
          });
    }
  },
  mounted() {
    this.offers();
    this.handBookData();
  },
}
</script>

<style scoped>
.dropstart .dropdown-toggle::before {
  display: none;
}
.list-group-item.custom-active {
  background-color: #f8f9fa;
}
</style>