<template>
    <div class="header" >
      <div class="navbar-vertical border-end bg-white navbar-vertical-aside-mobile-overlay" :class="this.$store.state.showTopMenu == true ? 'collapsed ' : 'uncollapsed'">
        <div class="navbar-brand justify-content-between">
          <img alt="" class="navbar-logo" src="http://position.dkcapital.ge/front-dashboard/assets/svg/logos/dk_crm_logo.png">
          <button class="border-0 p-0 bg-transparent close d-block d-md-none" type="button" @click="toggleMenu">
            <i class="tio-clear tio-lg"></i>
          </button>
        </div>
        <div class="navbar-vertical-content">
          <ul class="nav flex-column navbar-tabs">
            <li class="nav-item active">
              <router-link class="nav-link" aria-current="page" to="/">
                <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                  <i class="fas fa-th nav-icon"></i>
                  <span>დეშბორდი</span>
                </span>
              </router-link>
            </li>
            <li class="nav-item">
              <small class="nav-subtitle">გვერდები</small>
            </li>

            <!-- Users -->
            <li class="nav-item" :class="{ show: isActive == 'link-1' }">
              <div class="nav-link nav-link-toggle" @click.prevent="navItem('link-1')">
                <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                  <i class="tio-user nav-icon"></i>
                  <span>მომხმარებელი</span>
                </span>
              </div>
              <transition name="fade">
                <ul class="vertical-aside-submenu nav nav-sub" v-if="isActive == 'link-1'">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Permission">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>ფერმიშენი</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Roles">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>როლები</span>
                     </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Create">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>მომხმარებლის დამატება</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Users">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>მომხმარებლები</span>
                      </span>
                    </router-link>
                  </li>
                </ul>

              </transition>
            </li>

            <!-- Location Types -->
            <li class="nav-item d-none" :class="{ show: isActive == 'link-2' }">
              <div class="nav-link nav-link-toggle" @click.prevent="navItem('link-2')">
                <i class="tio-poi nav-icon"></i>
                <span>ტიპები</span>
              </div>
              <transition name="fade">
                <ul class="vertical-aside-submenu nav nav-sub" v-if="isActive == 'link-2'">
                  <li class="nav-item">
                    <router-link  class="nav-link" to="/Region">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>რეგიონები</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/District">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>რაიონი</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Address">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>ქუჩა</span>
                      </span>
                    </router-link>
                  </li>
                  <!-- <li class="nav-item">
                    <a class="nav-link" href="#">
                      <span class="tio-circle nav-indicator-icon"></span>
                      <span>მომხმარებლები</span>
                    </a>
                  </li> -->
                </ul>

              </transition>
            </li>

            <!-- Clients -->
            <li class="nav-item" :class="{ show: isActive == 'link-3' }">
              <div class="nav-link nav-link-toggle" @click.prevent="navItem('link-3')">
                <i class="tio-contacts-book nav-icon"></i>
                <span>კლიენტები</span>
              </div>
              <transition name="fade">
                <ul class="vertical-aside-submenu nav nav-sub" v-if="isActive == 'link-3'">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Retail_Clients">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>რითეილ კლიენტები</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item d-none">
                    <router-link class="nav-link" to="/Corporate_Clients">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>კორპორატიული კლიენტები</span>
                      </span>
                    </router-link>
                  </li>
                </ul>

              </transition>
            </li>

            <!-- Developers -->
            <li class="nav-item d-none" :class="{ show: isActive == 'link-4' }">
              <div class="nav-link nav-link-toggle" @click.prevent="navItem('link-4')">
                <i class="tio-company nav-icon nav-icon"></i>
                <span>დეველოპერები</span>
              </div>
              <transition name="fade">
                <ul class="vertical-aside-submenu nav nav-sub" v-if="isActive == 'link-4'">
                  <li class="nav-item">
                    <router-link class="nav-link" to="#">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>დეველოპერების სია</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="#">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>რეპორტი დეველოპერები</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="#">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>რეპორტი დეველოპერები</span>
                      </span>
                    </router-link>
                  </li>
                </ul>

              </transition>
            </li>

            <!-- Deals -->
            <li class="nav-item" :class="{ show: isActive == 'link-5' }">
              <div class="nav-link nav-link-toggle" @click.prevent="navItem('link-5')">
                <i class="tio-launch-outlined nav-icon"></i>
                <span>დილები</span>
              </div>
              <transition name="fade">
                <ul class="vertical-aside-submenu nav nav-sub" v-if="isActive == 'link-5'">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Deals_Overview">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>ჩემი დილები</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Retail_Client_Information">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>ჩემი გუნდის დილები</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/Retail_Client_Information">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>რითეილ დილის დამატება</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item d-none">
                    <router-link class="nav-link" to="/Corporate_Client_Information">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>კორპორატიული დილის დამატება</span>
                      </span>
                    </router-link>
                  </li>
                </ul>

              </transition>
            </li>

            <!-- Review -->
            <li class="nav-item" v-if="offer_count">
              <router-link class="nav-link text-custom-warning" aria-current="page" to="/Deals/offers">
                <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                  <i class="tio-notice-outlined nav-icon"></i>
                  <span>განსახილველი <span class="badge bg-custom-warning ms-1 p-1">{{ offer_count }}</span> </span>
                </span>
              </router-link>
            </li>


            <!-- Statements -->
            <li class="nav-item d-none" :class="{ show: isActive == 'link-6' }">
              <div class="nav-link nav-link-toggle" @click.prevent="navItem('link-6')">
                <i class="tio-folder-opened-labeled nav-icon"></i>
                <span>ხელშეკრულება</span>
              </div>
              <transition name="fade">
                <ul class="vertical-aside-submenu nav nav-sub" v-if="isActive == 'link-6'">
                  <li class="nav-item">
                    <router-link class="nav-link" to="#">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>ყველა ხელშეკრულება</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="#">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>სატარიფო ბადე</span>
                      </span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="#">
                      <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                        <span class="tio-circle nav-indicator-icon"></span>
                        <span>დრაფტის შექმნა</span>
                      </span>
                    </router-link>
                  </li>
                </ul>

              </transition>
            </li>

            <!-- Academy -->
            <li class="nav-item d-none">
              <router-link class="nav-link" aria-current="page" to="/Academy">
                <span class="d-flex align-items-center" @click="delete_Deal_steps()">
                  <i class="tio-education nav-icon"></i>
                  <span>აკადემია</span>
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-cover" @click="toggleMenu" :class="this.$store.state.showTopMenu == true ? '' : 'd-none'"></div>

      <nav class="navbar navbar-horizontal border-bottom pt-0 pb-0" :class="this.$store.state.showTopMenu == true ? 'collapsed ' : 'uncollapsed'">
        <div class="container-fluid px-0">
          <button class="navbar-brand border-0 p-0 bg-transparent close" type="button" @click="toggleMenu">
            <i class="fas fa-bars"></i>
          </button>
          <div class="navbar-brand navbar-disabled p-0 m-0" v-if="this.$store.state.showTopMenu == true">
            <img alt="" class="navbar-logo" src="http://position.dkcapital.ge/front-dashboard/assets/svg/logos/dk_crm_logo.png">
          </div>
          <div class="navbar-brand p-0 m-0 d-block d-md-none">
            <img alt="" class="navbar-logo" src="http://position.dkcapital.ge/front-dashboard/assets/svg/logos/dk_crm_logo.png">
          </div>
          <ul class="navbar-nav align-items-center flex-row">
            <li class="me-2 d-none d-md-block">
              <div class="position-relative d-inline-block">
                <a class="btn btn-icon btn-outline-light position-relative rounded-circle"  @click.prevent="navItem('button-1')">
                  <i class="fas fa-bell text-secondary"></i>
                  <span class="btn-status btn-sm-status bg-danger rounded-circle"></span>
                </a>
              </div>

              <transition name="fade">
              <div class="dropdown_menu dropdown-menu-right navbar-dropdown-menu" v-if="isActive == 'button-1'">
                <!-- Header -->
                <div class="card-header px-3 border-bottom">
                  <span class="card-title">შეტყობინებები</span>

                  <!-- Unfold -->
                  <div class="position-relative d-inline-block">

                  </div>
                  <!-- End Unfold -->
                </div>
                <!-- End Header -->

                <!-- Card Footer -->
                <a class="card-footer text-center px-3 d-block" href="#">
                  შეტყობინება არ არის &nbsp;&nbsp;
                  <i class="fas fa-angle-right" style="margin-top: 3px"></i>
                </a>
                <!-- End Card Footer -->
              </div>
              </transition>
            </li>
            <li>
              <div class="position-relative d-inline-block">
                <a class="navbar-horizontal-profile"  @click.prevent="navItem('button-2')">
                  <div class="avatar avatar-sm avatar-circle">
                    <img class="avatar-img" src="http://position.dkcapital.ge/front-dashboard/assets/img/160x160/img6.jpg" alt="Image Description">
                    <span class="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div>
                </a>
              </div>
              <transition name="fade">
                <div class="dropdown_menu dropdown-menu-right" v-if="isActive == 'button-2'">
                  <div class="dropdown_item_text">
                    <div class="media align-items-center">
                      <div class="avatar avatar-sm avatar-circle me-2">
                        <img class="avatar-img" src="https://dkhome.ge/assets/img/160x160/img6.jpg" alt="Image Description">
                      </div>
                      <div class="media-body">
                        <span class="card-title mb-0">რატი წიკლაური</span>
                        <span class="card-text">rati@dkcapital.ge</span>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown_item" href="#">
                    <span class="text-truncate pr-2" title="Profile &amp; account">პროფილი</span>
                  </a>
                  <a class="dropdown_item" href="#">
                    <span class="text-truncate pr-2" title="Profile &amp; account">გასვლა</span>
                  </a>
                </div>
              </transition>
            </li>
          </ul>
        </div>
      </nav>
    </div>

</template>


<script>
import { beforeRouteLeave } from 'vue-router';


import axios from 'axios';
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  data () {
    return {
      isActive: null,
      showTopMenu: false,
      offer_count: ''
    }
  },
  methods: {
    navItem(linkIdent) {
      this.isActive = this.isActive === linkIdent ? null : linkIdent
    },
    toggleMenu() {

      if (this.$store.state.showTopMenu == true) {
        this.$store.state.showTopMenu = false;
      } else {
        this.$store.state.showTopMenu = true;
      }
    },

    dealOverview() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/deal/getOverview', config)
        .then((res) => {
          this.offer_count= res.data.offer

        })
        .catch((error) => {
          var error_response = error.response.data;
          if (error.response.status == 422) {
            this.setErrors(error.response.data.errors);
          } else {
            this.setErrors(error.response.data.message);
          }
        });
    },

    updateDeal() {
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/update/deals', config)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_Deal_steps() {
      localStorage.removeItem('Step_1');
      localStorage.removeItem('Step_2');
      localStorage.removeItem('Step_3');
    }

  },
  mounted() {
    this.dealOverview();
    this.updateDeal();

    this.$store.state.showTopMenu = false;


    try {
      JSON.parse(localStorage.getItem('user'))

    } catch (e) {
      localStorage.removeItem('user');
      this.$router.push('/signin')
    }
    const user_info = JSON.parse(localStorage.getItem('user'));
    this.name = user_info.user.name;
    this.email = user_info.user.email;
    const token = JSON.parse(localStorage.getItem('user')).access_token;



    axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/check-user', { 'data': localStorage.getItem('user') }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {

        /*
              this.$store.state.permission=res.data.permission;
        */
        /*
        
        
              this.$store.state.commit("setCurrentPermission", res.data.permission);*/





      })
      .catch((error) => {

        localStorage.removeItem('user');
        this.$router.push('/signin')

      });

    this.$store.state.sideBarMenuAccordion();
    


  },
  computed: {
    ...mapGetters({ permission: "getCurrentPermission" })
  }
}
</script>

<style scoped>
.navbar-cover {
  z-index: 101 !important;
  opacity: 1 !important;
  width: 100% !important;
  height: 100% !important;
}

.navbar-cover {
  background-color: rgba(19,33,68,.25);
}
.navbar-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity .2s ease;
}
</style>